/** @require: var compatdragdrop = require('@mod-system/js/compat/dragdrop');
*/
import { $, Element, Browser } from "@mod-system/js/frameworks/mootools/core";
var legacybase = require('@mod-system/js/internal/legacybase');
import * as browser from 'dompack/extra/browser';
import * as dragdropfix from '@mod-system/js/internal/dragdropfix';

/** Drag and drop compatibility library

    This library fixes some browser quirks related to mouse coordinates in drag
    and drop events, and it makes all elements having the draggable attribute
    set actually draggable in Internet Explorer, by calling the dragDrop function
    in the element's mousedown handler.

    Example of drag and drop usage:

    <!-- Some drag sources -->
    <div id="jane_smith" draggable="true">Jane Smith</div>
    <div id="john_doe" draggable="true">John Doe</div>
    <div id="anonymous" draggable="true">Anonymous</div>
    <!-- The drop target -->
    <div id="names" />

    $("jane_smith").addEvent("dragstart", function(event)
    {
      // Set some 'x-example/person' data
      $wh.dndSetData(event, "x-example/person", { name: "Jane Smith", age: 25 });
      // Allow the 'copy' effect
      event.event.dataTransfer.effectAllowed = "copy";
    });
    $("john_doe").addEvent("dragstart", function(event)
    {
      // Set some 'x-example/person' data
      $wh.dndSetData(event, "x-example/person", { name: "John Doe", age: 26 });
      // Allow the 'copy' effect
      event.event.dataTransfer.effectAllowed = "copy";
    });
    $("anonymous").addEvent("dragstart", function(event)
    {
      // Set some non-'x-example/person' data, which won't be accepted by our target
      $wh.dndSetData(event, "mytype", { name: "Anonymous", age: 0 });
      // Allow the 'copy' effect
      event.event.dataTransfer.effectAllowed = "copy";
    });

    $("names").addEvent("dragenter", function(event)
    {
      // Prevent default to enable dropping on this element
      event.preventDefault();
    }).addEvent("dragover", function(event)
    {
      // Get the stored WebHare data types
      var types = $wh.dndTypes(event);
      // See if 'x-example/person' data was dropped
      if (types && types.contains("x-example/person"))
      {
        // Set the drop effect to 'copy'
        event.event.dataTransfer.dropEffect = "copy";
        // Prevent default to enable dropping on this element
        event.preventDefault();
      }
      else
      {
        // We don't accept this drop, don't prevent default so dropping is not possible
      }
    }).addEvent("drop", function(event)
    {
      // Get the 'x-example/person' data
      var person = $wh.dndGetData(event, "x-example/person")
      // If we have a person, add him/her to the list of names
      if (person)
        this.grab(new Element("div", { text: person.name + " (" + person.age + ")" }));
      // Prevent the browser from trying to navigate to the data URL
      event.preventDefault();
    });
*/

// Use the 'dragover' event on the document to capture the mouse coordinates
// and apply them in the 'drag' and 'dragend' events. This only fails in Firefox
// and because there is no behaviour test to test for this, we'll have to check
// the Browser name.
// Internet Explorer 10 on the desktop (and only the desktop version) does not
// update the mouse position in drag events, and doesn't fire the 'mousemove'
// event while dragging, so there is no way to know the mouse position in drag
// events, other than the initial position (on 'dragstart'). Unfortunately,
// there is no way of knowing if we're in the desktop version of IE10 or the
// Metro ("Windows UI") version (which does supply the correct mouse
// coordinates)...
// See: http://msdn.microsoft.com/en-us/library/ie/hh771832%28v=vs.85%29.aspx#uastring
var fix_dragevent_mousepos = Browser.firefox;

// To make elements draggable in HTML5, the 'draggable' attribute should be
// set. In Internet Explorer, this isn't supported, and the 'dragDrop'
// function should be called on mousedown. We'll add an Element property which
// adds (or removes) an event handler upon setting the 'draggable' property.
// On domready, we'll also add the event handler to all elements having the
// 'draggable' attribute set in HTML.
// In Internet Explorer 10, support for the 'draggable' attribute was added,
// and using this attribute in combination with calling dragDrop will result
// in double drag actions, so only use dragDrop if draggable is not available.
//var fix_draggable_attribute = !("draggable" in document.createElement("div")) && "dragDrop" in document.createElement("div");

// Make drag and drop events native MooTools events
Element.NativeEvents.dragstart = 2;
Element.NativeEvents.drag = 2;
Element.NativeEvents.dragend = 2;
Element.NativeEvents.dragenter = 2;
Element.NativeEvents.dragover = 2;
Element.NativeEvents.dragleave = 2;
Element.NativeEvents.drop = 2;

// Store the last mouse position for dragging events in Firefox
var dndlastmousepos = {};

// Our custom data url
var webharedataurl = "webhare://data/";

// The custom data type we're using to store our drag and drop data
var webharedatatype = "x-webhare/data";
// IE doesn't seem to support setting "url", so we'll fall back to "text"
var fallbackdatatype = "Text";

var effectstrs = [ 'none', 'copy', 'move', 'copyMove', 'link', 'copyLink', 'linkMove', 'all' ];

// Get the dataTransfer object for a dragging event
function getDataTransfer(event)
{
  if (event)
  {
    // DOM event
    if (event.dataTransfer)
      return event.dataTransfer;

    // MooTools event
    if (event.event)
    {
      if (event.event.dataTransfer)
        return event.event.dataTransfer;
    }
  }
}

// Retrieve the WebHare data stored from our custom data url
function getWebHareData(event)
{
  // Get the event's dataTransfer object
  var transfer = getDataTransfer(event);
  if (!transfer)
    return;

  // Get the data from the dataTransfer object
  var data;
  try
  {
    // Prefer our custom data type
    data = transfer.getData(webharedatatype);
  }
  catch (e)
  {
    // Using our custom data type failed, use the fallback data type
    data = transfer.getData(fallbackdatatype);
  }
  if (!data)
    return;

  // Check if this is a WebHare data URL
  if (data.substr(0, webharedataurl.length) != webharedataurl)
    return;

  // Retrieve and decode the data
  return JSON.parse(decodeURIComponent(data.substr(webharedataurl.length)), true);
}

// Store the WebHare data in our custom data url
function setWebHareData(event, data)
{
  // Get the event's dataTransfer object
  var transfer = getDataTransfer(event);
  if (!transfer)
    return;

  // The data, encoded within a URL
  data = webharedataurl + encodeURIComponent(JSON.stringify(data));

  // Clear any existing data
  transfer.clearData();

  try
  {
    // Prefer our custom data type
    transfer.setData(webharedatatype, data);
  }
  catch (e)
  {
    // Using our custom data type failed, use the fallback data type
    transfer.setData(fallbackdatatype, data);
  }
}

// Call the dragDrop function to initiate drag and drop. Called within the
// context of the element receiving the mousedown.

var currentdrag = null;

function startDragMonitor(event)
{
  // Reset state
  this.removeEvents();
  if(event.event.button) //not the primary button
    return true;

  // get the node we set the mouseDown event on
  var target = event.event.currentTarget;
  currentdrag =
      { target: target // node we set the mouseDown event on
      , x:      event.page.x
      , y:      event.page.y
      };

  target.addEvent('mousemove', onMouseMove);
  target.addEvent('mouseout', onMouseOut);
  target.addEvent('mouseup', onMouseUp);
  return true;
}

function removeEvents(target)
{
  if (!target)
  {
    if (!currentdrag)
      return null;
    target = currentdrag.target;
  }

  target.removeEvent('mousedown', startDragMonitor);

  // Dragging the current node? Remove other events & reset drag state
  if (currentdrag && currentdrag.target == target)
  {
    currentdrag = null;
    target.removeEvent('mousemove', onMouseMove);
    target.removeEvent('mouseout', onMouseOut);
    target.removeEvent('mouseup', onMouseUp);
  }
  return target;
}

function onMouseMove(event)
{
  //console.log('ie9 movetest', event.page.y, currentdrag.y, event.page.x, currentdrag.x);
  if (Math.abs(event.page.y - currentdrag.y) >= 5 || Math.abs(event.page.x - currentdrag.x) >= 5)
  {
    var target = removeEvents();
    if (target)
    {
      target.dragDrop();
      return false;
    }
  }
  return true;
}

function onMouseOut(event)
{
  var target = removeEvents();
  if (target)
  {
    target.dragDrop();
    return false;
  }
  return false;
}

function onMouseUp(event)
{
  removeEvents();
  return true;
}

/*if (fix_draggable_attribute)
{
  Element.Properties.draggable =
  {
    get: function()
    {
      return this.draggable;
    },

    set: function(value)
    {
      this.draggable = !!value;

      if (this.draggable)
        this.addEvent("mousedown", startDragMonitor);
      else
        removeEvents(this);
    }
  };
}
*/
document.addEvent("domready", function()
{
  // Add a mousedown handler to each element that has the 'draggable' attribute
  // set (see above).
//  if (fix_draggable_attribute)
 //   $$("[draggable]").addEvent("mousedown", startDragMonitor);

  // Firefox doesn't supply coordinates with drag-related events, so we'll
  // just keep track of where the mouse is in some global state.
  // See: https://bugzilla.mozilla.org/show_bug.cgi?id=505521
  if (fix_dragevent_mousepos)
  {
    // Add these events to the documentElement, so they will fire when
    // dragging outside of the document body (which is only as tall as its
    // contents). Adding them to window or document also doesn't work.
    $(document.documentElement).addEvent("mousedown", function(event)
    {
      // Initialize on mousedown, because 'drag' events fire before 'dragover'.
      // This also means we're one step behind, but that's better than having
      // no coordinates at all.
      dndlastmousepos = { "client": event.client
                            , "page": event.page
                            };
    });
    $(document.html).addEvent("dragover", function(event)
    {
      dndlastmousepos = { "client": event.client
                            , "page": event.page
                            };
    });
  }
});

// Extend the MooTools DOMEvent to fix some browser quirks for drag/drop events and to add support for our custom move events
legacybase.extendDOMEventConstructor(function(event, win)
{
  dragdropfix.fixupDNDEvent(event);
  if (this.type && (this.type == 'drop' || this.type == 'movestart' || this.type == 'move' || this.type == 'moveend' || this.type.indexOf('drag') == 0))
  {
    var doc = win.document;
    doc = (!doc.compatMode || doc.compatMode == 'CSS1Compat') ? doc.html : doc.body;
    // Initialize this.page, this.client and this.rightClick (like the DOMEvent does for all mouse events)
    this.page = { x: (event.pageX != null) ? event.pageX : event.clientX + doc.scrollLeft
                , y: (event.pageY != null) ? event.pageY : event.clientY + doc.scrollTop
                };

    this.client = { x: (event.pageX != null) ? event.pageX - win.pageXOffset : event.clientX
                  , y: (event.pageY != null) ? event.pageY - win.pageYOffset : event.clientY
                  };
    this.rightClick = event.button==2;
    // Add this.moved object for our custom move events
    if (this.type == 'movestart' || this.type == 'move' || this.type == 'moveend')
    {
      this.moved = { x: event.movedX
                   , y: event.movedY
                   };
    }

    // Use the stored mouse position for the "drag" and "dragend"
    // events.
    if (fix_dragevent_mousepos && (this.type == "drag" || this.type == "dragend"))
    {
      this.client = dndlastmousepos.client;
      this.page = dndlastmousepos.page;
    }

    // Record the dragged node
    if (this.type == 'dragstart')
    {
      var dt = getDataTransfer(event);
      if (dt)
        dt.allowedEffects = 'all';
    }

    // And now for some truly bizarre browser quirks:

    if(this.type == "dragend" && browser.getPlatform() == "mac")
    {
      // In Safari on Mac, the y coordinate for the 'dragend' event is
      // relative to the body bottom.
      if (browser.getName() == "safari")
      {
        var bodyheight = $(document.body).getSize().y;
        this.page.y = bodyheight - this.page.y;
        this.client.y = bodyheight - this.client.y;
      }

      // In Chrome on Mac, the y coordinate for the 'dragend' event is
      // 26 pixels too high.
      if (browser.getName() == "chrome")
      {
        this.page.y -= 26;
        this.client.y -= 26;
       }
     }

    //ADDME: The last 'drag' event fired (when releasing the mouse
    //       button) in Chrome on Mac or Windows has all mouse
    //       coordinates set to 0. Is there some way to detect and
    //       correct this? For the moment: no.
  }

  if (!this.moved) this.moved = {};
});
function parseEffectList(data)
{
  data = Array.convert(data || 'all');
  var mask = 0;
  for (var i = 0; i < data.length; ++i)
  {
    var pos = effectstrs.indexOf(data[i]);
    if (pos >= 0)
      mask = mask | pos;
  }
  return effectstrs[mask];
}

class DragStartData
{

  constructor(effectAllowed)
  {
    /// Allowed drop effect
    this.effectallowed = 'all';

    /// Data to present to external (webhare) targets
    this.externaldata = null;

    /// Data to present to local (webhare) targets
    this.localdata = null;

    /** File to present for external file downloads
        @cell mimetype
        @cell filename
        @cell url
    */
    this.file = null;

    this.effectallowed = parseEffectList(effectAllowed);
  }

  storeIntoEvent(event)
  {
    currentdrag = this;

    var dataTransfer = getDataTransfer(event);
    dataTransfer.effectAllowed = this.effectallowed;

    setWebHareData(event, this.externaldata ? JSON.stringify(this.externaldata) : '');

    if (this.file)
    {
      try
      {
        var url = this.file.mimetype + ':' + this.file.filename + ':' + this.file.url;

        dataTransfer.setData('DownloadURL', url);
        dataTransfer.setData('URL', this.file.url);
      }
      catch(e)
      {
        //IE9 fails on dataTransfer.setData
      }
    }
  }
};

class CurrentDragData
{

  constructor(event, localdrag)
  {
    /// Current event
    this.event = event;

    /// Local associated drag
    this.localdrag = localdrag;
  }

  /// Drag from external source?
  hasExternalSource()
  {
    return !this.localdrag;
  }

  haveDataAccess()
  {
    return this.localdrag || this.event.type == 'drop';
  }

  isFileDrag()
  {
    return this.getTypes().includes("Files");
  }

  /// Data (local from local source, external for external sources)
  getData()
  {
    return this.localdrag ? this.localdrag.localdata : getWebHareData(this.event);
  }

  getFiles()
  {
    var datatransfer = getDataTransfer(this.event);
    return datatransfer ? datatransfer.files || [] : [];
  }

  getItems()
  {
    var datatransfer = getDataTransfer(this.event);
    return datatransfer ? Array.from(datatransfer.items) || [] : [];
  }

  getTypes()
  {
    var datatransfer = getDataTransfer(this.event);
    return datatransfer ? datatransfer.types || [] : [];
  }

  getDropEffect()
  {
    var datatransfer = getDataTransfer(this.event);
    var mode = datatransfer ? datatransfer.dropEffect : "";

    return [ 'copy', 'move', 'link' ].includes(mode) ? mode : 'move';
  }

  setDropEffect(mode)
  {
    var datatransfer = getDataTransfer(this.event);
    if (!datatransfer)
      return;
    if ([ 'copy', 'move', 'link', 'none' ].includes(mode))
      datatransfer.dropEffect = mode;
  }

  setDefaultDropEffect()
  {
    var datatransfer = getDataTransfer(this.event);
    if (!datatransfer)
      return;
    // Set default drop effect for allowed effects
    datatransfer.dropEffect = dragdropfix.getDefaultDropEffect(this.event, datatransfer.effectAllowed);
  }
}

function getDragData(event)
{
  return new CurrentDragData(event, currentdrag);
}

/// Reset the current drag when a local drag has ended
document.addEvent('dragend', function() { currentdrag = null; });

module.exports = { DragStartData: DragStartData
                 , getDragData: getDragData
                 , fixupDNDEvent: dragdropfix.fixupDNDEvent
                 };

window.__dragdroploaded = true;
