/** @require: var domui = require('@mod-system/js/internal/legacyui');
*/
const WHBase = require('@mod-system/js/compat/base');
const domtools = require('@mod-system/js/dom/tools');
var legacybase = require('@mod-system/js/internal/legacybase');

function changeValue(element, newvalue, options)
{
  if(window.Elements && element instanceof window.Elements) //moo compatibility
  {
    Array.from(element).forEach(node => changeValue(node, newvalue));
    return;
  }
  domtools.changeValue(element, newvalue, options);
}

// register replace instructions, so we can reapply them on new elements
var replaces = [], seendomready = false;

function executeReplaceInstruction(startnode, instruction)
{
  //ADDME: checking this here prevents possible error from occurring in Firefox:
  //       "TypeError: Argument 1 of Node.contains does not implement interface Node."
  if (!instruction.selector || !instruction.selector.length || (WHBase.debug.nor && !instruction.options.mustreplace))
    return;

  //Note: we can't do startnode.getElements(), as a common selector "form select" would not match newly added arrayrows (startnode is inside the form)
  var elements = legacybase.getTopMatchedElements(startnode, instruction.selector);

  Array.each(elements, function(node)
    {
      var replacedby = node.retrieve("wh-ui-replacedby");
      //console.log("Replacing",node,node.getAttribute("replaceid"),replacedby)
      if(replacedby)
      {
        if(replacedby.nextSibling != node) //it got moved away
          replacedby.inject(node, 'before');
        return;
      }

      // Internet Explorer and Edge don't treat <template> as inert, so content within <template> will be picked up.
      // Replacement must be done after instantiation of the template because any events and references to nodes
      // will be lost. (will be on the template instead of new DOM)
      if (node.getParent("template"))
        return;

      //FIXME replace this with <template> support
      var templaterowparent = node.getParent(".templaterow");
      if(templaterowparent && templaterowparent.getParent(".wh-form"))
        return;
      instruction.replacefunction(node, instruction.options);
    });
}

function executeReplaceDomready()
{
  // FIXME readd ?  still used ?  such gloabls are scary if component's requirements mismatch : if ($wh.autoreplacecomponents)
  {
    seendomready = true;
    applyReplaceableComponents(null);
  }
}

/* Passing 'mustreplace:true' as option will prevent WHBase.debug.nor from disabling the element replacement */
function setupReplaceableComponents(selector, replacefunction, options)
{
  options = options ? Object.clone(options) : {};

  var instr = { selector: selector
              , replacefunction: replacefunction
              , options: options
              };
  replaces.push(instr);
  if(seendomready)
    executeReplaceInstruction(null, instr);
}
/** Fire replaced component handlers on a inserted/updated parts of the dom
    @param basenode DOM tree to update */
function applyReplaceableComponents (basenode)
{
  replaces.each(executeReplaceInstruction.bind(window, basenode));
}


//////////////////////////////////////////////////////////////////////////////
//
// The UI 'busy' system
//
//this handler watches for clicks targetted to replace UI components, and fixes up any needed focus handling
function fallbackLabelHandler(event)
{
  if(fallbackLabelHandler.cancelSubsequentClicks)
  {
    //console.log("BREAKING CLICK", event.target);
    event.stop();
    return;
  }

  var label;
  for(var node = event.target; node; node=node.parentNode)
  {
    if(!node.nodeName)
      continue;

    var testnode = node;
    if(testnode.retrieve)
    {
      var replaces = testnode.retrieve("wh-ui-replaces");
      if(replaces)
      {
        testnode = replaces;
      }

    }

    var name = testnode.nodeName.toUpperCase();
    if(["INPUT", "A", "SELECT"].contains(name))
    {
      if(name=="SELECT") //a select inside a label triggers two click events on safari (confirmed Safari 7 & Yosemite Beta 8, Firefox). prepare to swallow the second click
      {
        fallbackLabelHandler.cancelSubsequentClicks = true;
        (function() { fallbackLabelHandler.cancelSubsequentClicks = false; }).delay(0);
        event.stop();
      }
      return; //do not intercept this link
    }

    if (name == "LABEL")
    {
      label = node;
      break;
    }
  }
  if(!label)
    return;

  //find the actual target
  var targetelement = label.htmlFor ? $(label.htmlFor) : domfocus.getFocusableComponents(label,false).pick();
  if(!targetelement)
    return;
  var replacedby = targetelement.retrieve("wh-ui-replacedby");
  if(replacedby)
    targetelement=replacedby;

  targetelement.focus();
  targetelement.click();
  event.stop();
}

function setupLabelDefaultHandler()
{
  if(setupLabelDefaultHandler.executed)
    return;
  setupLabelDefaultHandler.executed=true;

  $(document).addEvent("click", fallbackLabelHandler);
}
setupLabelDefaultHandler.executed=false;

var setupFormResetListener = function(input, onchange)
{
  // Listen to form resets
  var parentform = input.getParent("form");
  if(parentform)
  {
    // Check if the form doesn't already have a reset listener
    if (!parentform.retrieve("wh-formresetlistener"))
    {
      parentform.addEvent("reset", function()
      {
        // Delay the reset, giving the onreset time to update the handlers
        (function()
        {
          // Fire the 'form is reset' event on all inputs and selects within the form
          this.getElements("input, select").fireEvent("wh-formisreset");
        }).delay(1, this); // this == 'reset' event target == form
      });

      // Set the form reset listener to prevent multiple listeners on the same form
      parentform.store("wh-formresetlistener", true);
    }

    // Listen to the 'form is reset' event, fire the onchange handler if the form was reset
    input.addEvent("wh-formisreset", onchange);
  }
};

module.exports = { changeValue: changeValue
                 , setupReplaceableComponents: setupReplaceableComponents
                 , applyReplaceableComponents: applyReplaceableComponents
                 , setupLabelDefaultHandler: setupLabelDefaultHandler
                 , setupFormResetListener: setupFormResetListener
                 };

window.addEventListener("DOMContentLoaded", executeReplaceDomready);
