import * as dompack from 'dompack';
import ComponentBase from '@mod-tollium/js/component/base';
import AutoSuggest from "dompack/components/autosuggest";
import './textedit.scss';

import { InputTextLengthCounter } from "@mod-tollium/web/ui/components/basecontrols/counter";
import * as toddtools from '@mod-tollium/js/component/tools';
var $todd = require('@mod-tollium/web/ui/js/support');

export class ObjAutoSuggestableBase extends ComponentBase
{
  constructor(parentcomp, data, replacingcomp)
  {
    super(parentcomp, data, replacingcomp);
    this._autosuggest = data.autosuggest;
  }

  // ---------------------------------------------------------------------------
  // Lookup support
  //
  async lookup(word)
  {
    if(this._autosuggest.type == 'static')
    {
      //startswith matches go in the top half, other matches in the bottom half
      let toplist = [], bottomlist = [];
      word = word.toLowerCase();

      for(let entry of this._autosuggest.vals)
        if(entry.toLowerCase().startsWith(word))
          toplist.push(entry);
        else if(entry.toLowerCase().includes(word))
          bottomlist.push(entry);

      return toplist.concat(bottomlist);
    }

    let lookupdefer = dompack.createDeferred();
    this.asyncMessage('lookup', { word }, { modal: false });
    this._resolveresult = lookupdefer.resolve;
    return lookupdefer.promise;
  }

  onMsgLookupResult(result)
  {
    this._resolveresult(result.vals);
  }

  setupAutosuggest(node)
  {
    if(!this._autosuggest)
      return null;

    return new AutoSuggest(node, this, { baseclass: 't-selectlist', minlength: this._autosuggest.minlength });
  }
}

export default class ObjTextEdit extends ObjAutoSuggestableBase
{
  // ---------------------------------------------------------------------------
  //
  // Initialization
  //

  constructor(parentcomp, data, replacingcomp)
  {
    super(parentcomp, data, replacingcomp);
    this.componenttype = "textedit";
    this.lastreportedvalue = '';
    this.reportchange_cb = null;
    this.showcounter = false;
    this.maxlength = 0;
    this.maxlengthmeasure = false;
    this.type = '';
    this.inputnode = null;
    this.placeholder = '';
    this.buttons = [];
    this.setValue(data.value);
    this.placeholder = data.placeholder || "";
    this.showcounter = data.showcounter === true;
    this.maxlength = 0;
    this.maxlengthmeasure = data.maxlengthmeasure;
    this.autocomplete = data.autocomplete || [];
    if (data.maxlength >= 0 && !data.password) //Never accept a maxlength on passwords, as it's not obvious you typed too much characters
      this.maxlength = data.maxlength;

    this.type = data.password ? 'password' : 'text';

    this.buttons = [];
    if (data.buttons)
      data.buttons.forEach(button =>
      {
        var comp = this.owner.addComponent(this, button);
        this.buttons.push(comp);
      });

    // Build our DOM
    this.buildNode();

    this.inputnode.addEventListener("focus", evt => this._gotFocus(evt));
    this.node.addEventListener("focusin", evt => this._gotFocus(evt));
    this.inputnode.addEventListener("input", evt => this.onAnyChange(evt));

    this.setRequired(data.required);
    this.setEnabled(data.enabled);
    this._autosuggester = this.setupAutosuggest(this.inputnode);
  }

  // ---------------------------------------------------------------------------
  // Component management
  //

  readdComponent(comp)
  {
    // Replace the offending component
    //if(!comp.parentsplititem)
    if(comp.parentcomp != this)
      return console.error('Child ' + comp.name + ' not inside the textedit is trying to replace itself');

    var newcomp = this.owner.addComponent(this, comp.name);
    this.buttons.splice(this.buttons.indexOf(comp), 1, newcomp);
    dompack.replaceWith(comp.getNode(), newcomp.getNode());
  }

  // ---------------------------------------------------------------------------
  //
  // Helper functions
  //

  doCopyToClipboard()
  {
    toddtools.copyValueToClipboard(this.inputnode);
  }

  /// Called after little timout to detect changes in value
  _reportChangesCallback()
  {
    this.reportchange_cb = null;

    this.setDirty();

    // Get the current value, compare with last reported value
    var currentvalue = this.getValue();
    if (this.lastreportedvalue != currentvalue && this.isEventUnmasked('change'))
    {
      // Only update lastreportedvalue when we're actually reporting.
      this.lastreportedvalue = currentvalue;
      this.transferState(false);
    }
  }

  // ---------------------------------------------------------------------------
  //
  // Property getters & setters
  //

  getSubmitValue()
  {
    // Get value to report. Also update lastreportedvalue, the backend now knows our value
    var value = this.getValue();
    this.lastreportedvalue = value;
    return value;
  }

  getValue()
  {
    return this.inputnode ? this.inputnode.get("value") : this.value;
  }

  setValue(value)
  {
    if (value != this.value)
    {
      this.value = value;
      if (this.inputnode)
        this.inputnode.set("value", this.value);
    }

    // Always update the last reported value, this instruction came from the backend
    this.lastreportedvalue = value;
  }

  setRequired(value)
  {
    if (value != this.required)
    {
      this.required = value;
      this.node.toggleClass("required", this.required);
    }
  }

  setEnabled(value)
  {
    if (value == this.enabled)
      return;

    this.enabled = value;
    this.node.toggleClass("disabled", !this.enabled);
    this.inputnode.set("readOnly", !this.enabled);
    this.inputnode.setAttribute("tabindex", 0);
  }

  // ---------------------------------------------------------------------------
  //
  // DOM
  //

  // Build the DOM node(s) for this component
  buildNode()
  {
    this.node = dompack.create("t-textedit", { dataset: { name: this.name }});
    this.node.propTodd = this;

    if(this.hint)
      this.node.set('title',this.hint);

    this.inputnode = dompack.create("input", { value: this.getValue()
                                             , type:  this.type
                                             , placeholder: this.placeholder.split("\n").join(", ")
                                             , autocapitalize: "off"
                                             , autocomplete: this.autocomplete.length ? this.autocomplete : "off"
                                             });

    // LastPass support, needs name="login/user/uname..." to detect as login field
    if (this.autocomplete.includes("username"))
      this.inputnode.name = "username";
    else if (this.autocomplete.includes("current-password"))
      this.inputnode.name = "password";

    this.node.appendChild(this.inputnode);
    if (this.maxlength > 0)
      this.inputnode.maxLength = this.maxlength;

    if(this.showcounter)
      new InputTextLengthCounter(this.node, { 'maxlengthmeasure' : this.maxlengthmeasure });

    for (let button of this.buttons)
      this.node.appendChild(button.getNode());
  }

  // ---------------------------------------------------------------------------
  //
  // Dimensions
  //

  getVisibleChildren()
  {
    return this.buttons;
  }

  getSkinSettings()
  {
    var styles = this.inputnode.getStyles([ "border-left-width", "border-right-width"
                                          , "padding-left", "padding-right"
                                          , "height", "min-height"
                                          , "margin-top"
                                          ]);
    return { x_overhead: styles["border-left-width"].toInt() + styles["border-right-width"].toInt() + styles["padding-left"].toInt() + styles["padding-right"].toInt()
           , height: styles["height"].toInt() || 0
           , minheight: styles["min-height"].toInt() || 0
           , padding: styles["padding-right"].toInt() || 0
           };
  }

  calculateDimWidth()
  {
    this.width.min = $todd.desktop.x_width*2 + this.skinsettings.x_overhead;
    this.buttons.forEach(button =>
    {
      button.width.min = 16;
      button.width.calc = 16;
      this.width.min += this.skinsettings.padding + button.width.calc;
    });
    this.width.calc = Math.max(this.width.min, $todd.settings.textedit_defaultwidth);
  }

  applySetWidth()
  {
    this.buttons.forEach(button =>
    {
      button.setWidth(button.width.calc);
    });
  }

  calculateDimHeight()
  {
    this.height.min = $todd.settings.grid_vsize;
    this.buttons.forEach(button =>
    {
      button.height.min = 16;
      button.height.calc = 16;
    });
  }

  applySetHeight()
  {
    this.buttons.forEach(button =>
    {
      button.setHeight(button.height.calc);
    });
  }

  relayout()
  {
    this.debugLog("dimensions", "relayouting set width=" + this.width.set + ", set height="+ this.height.set);
    let padding = this.skinsettings.padding;
    for (let idx = this.buttons.length - 1; idx >= 0; --idx)
    {
      let button = this.buttons[idx];
      this.buttons[idx].node.style.right = padding + "px";
      padding += this.skinsettings.padding + button.width.set;
      button.relayout();
    }
    this.inputnode.setStyles({ width:  this.width.set
                             , "padding-right": padding
                             });
  }


  // ---------------------------------------------------------------------------
  //
  // Events
  //

  onShow()
  {
    // Set placeholder just before showing the field, so our custom placeholder will be positioned correctly
    this.inputnode.set("placeholder", this.placeholder);
    return true;
  }

  _gotFocus()
  {
    this.owner.setComponentDefaultButton(this);
  }

  onAnyChange()
  {
    // Run change detect handler 100ms after last successive change
    if (this.reportchange_cb)
      clearTimeout(this.reportchange_cb);

    this.reportchange_cb = setTimeout( () => this._reportChangesCallback(), 100);
  }
}
