import * as dompack from 'dompack';
import ComponentBase from '@mod-tollium/js/component/base';

var $todd = require('@mod-tollium/web/ui/js/support');

var DateField = require('@mod-tollium/web/ui/components/basecontrols/calendar2');
var TimeField = require('@mod-tollium/web/ui/components/basecontrols/time');

import './datetime.scss';

DateField.replaceComponents("input.date", { });
TimeField.replaceComponents("input.time");

//var $todd = require("../support");


/****************************************************************************************************************************
 *                                                                                                                          *
 *  DATETIME                                                                                                                *
 *                                                                                                                          *
 ****************************************************************************************************************************/
export default class ObjDateTime extends ComponentBase
{
//, Properties: [ "required" ] FIXME?

/****************************************************************************************************************************
* Initialization
*/

  constructor(parentcomp, data, replacingcomp)
  {
    super(parentcomp, data, replacingcomp);
    this.componenttype = "datetime";
    this.datefield = null;
    this.timefield = null;
    this.lasterportedvalue = null;
    this.type=data.type;
    this.precision=data.precision;

    // Build our DOM
    this.fieldtype = data.fieldtype;
    this.placeholder = data.placeholder;
    this.dateformat = data.dateformat;
    this.cutoffyear = data.cutoffyear;
    this.buildNode(data);
    this.lastreportedvalue = "0000-00-00T00:00:00.000Z";
    this.setValue(data.value);

    this.node.addEventListener("change", this._reportChangesCallback.bind(this));

    this.setRequired(data.required);
    this.setEnabled(data.enabled);
  }

  // ---------------------------------------------------------------------------
  //
  // Helper functions
  //

  _padLeft(data, len)
  {
    data = '0000' + data;
    return data.substr(data.length - Math.max(data.length - 4, len));
  }

  _reportChangesCallback(event)
  {
    // Get the current value, compare with last reported value
    var currentvalue = this.getValue();
    if (this.lastreportedvalue != currentvalue)// && this.isEventUnmasked('change'))
    {
      this.setDirty();

      // Only update lastreportedvalue when we're actually reporting.
      this.lastreportedvalue = currentvalue;
      this.transferState(false);
    }
  }

  /** Store the value in the node, no callbacks
  */
  _setValueInternal(value)
  {
    var dateval='', timeval='';
    var tpos = value.indexOf('T');
    if(value && this.datefield)
    {
      var year = parseInt(value.substr(0, tpos - 4),10);
      var month = parseInt(value.substr(tpos - 4, 2),10);
      var day = parseInt(value.substr(tpos - 2, 2),10);

      //Just plain db format
      dateval = (year < 1000 ? ("000"+year).slice(-4) : year) + "-" + ("0"+month).slice(-2) + "-" +  ("0"+day).slice(-2);
    }

    if(value && this.timefield)
    {
      var hour = parseInt(value.substr(tpos + 1,2),10);
      var min = parseInt(value.substr(tpos + 3,2),10);
      var sec = parseInt(value.substr(tpos + 5,2),10);
      var msec = parseInt(value.substr(tpos + 8,3),10);

      timeval = ("0"+hour).slice(-2) + ":" + ("0"+min).slice(-2);
      if(this.precision=='seconds' || this.precision=='milliseconds')
        timeval += ':' + ("0"+sec).slice(-2);
      if(this.precision=='milliseconds')
        timeval += '.' + ("00"+msec).slice(-3);
    }

    if(this.datefield)
      this.datefield.set("value", dateval);
    if(this.timefield)
      this.timefield.set('value', timeval);
  }

/****************************************************************************************************************************
* Property getters & setters
*/

  getSubmitValue()
  {
    return this.getValue();
  }

  getValue()
  {
    var retval;
    var defaultdate = true;
    if(this.datefield) //FIXME support dateformat, validate
    {
      var datevalue = this.datefield.get('value');
      retval = '0000-00-00';
      let parts = datevalue.replace(/\//g,'-').split('-');
      if (parts.length == 3)
      {
        retval = this._padLeft(parts[0],4) + '-' + this._padLeft(parts[1],2) + '-' + this._padLeft(parts[2],2);//Just plain db format
        defaultdate = false;
      }
    }
    else
    {
      retval = '0001-01-01'; //ensure valid datetime if just sending time
    }
    retval += 'T';
    if(this.timefield)
    {
      // FIXME: parse correctly!!!!
      var timevalue = this.timefield.get('value');
      if (!timevalue && defaultdate)
        return "";
      let parts = timevalue.replace(/\./g,':').split(':');
      if (parts.length >= 2)
        retval += this._padLeft(parts[0],2) + ':' + this._padLeft(parts[1],2);
      else
        retval += '00:00';

      if ((this.precision=='seconds' || this.precision=='milliseconds') && parts.length >= 3)
        retval += ':' + this._padLeft(parts[2],2);
      else
        retval += ':00';

      if(this.precision=='milliseconds' && parts.length >= 4)
        retval += '.' + (parts[3]+'000').substr(0,3);
      else
        retval += '.000';
    }
    else
    {
      if (defaultdate)
        return "";
      retval += '00:00:00.000';
    }
    retval += 'Z';
    return retval;
  }
  /// Set the value. Report back changes when the value has changed
  setValue(value)
  {
    var oldval = this.getValue();
    this._setValueInternal(value);

    if (oldval !== this.getValue())
      this._reportChangesCallback();
  }
  setRequired(value)
  {
    if (value != this.required)
    {
      this.required = value;
      this.node.toggleClass("required", this.required);

      if (this.datefield)
      {
        this.datefield.required = this.required;
        this.datefield.fireEvent("wh-refresh");
      }

      if (this.timefield)
      {
        this.timefield.required = this.required;
        this.timefield.fireEvent("wh-refresh");
      }
    }
  }

  setEnabled(value)
  {
    if (value != this.enabled)
    {
      this.enabled = value;
      this.node.toggleClass("disabled", !this.enabled);

      if (this.datefield)
      {
        this.datefield.disabled = this.enabled ? null : "disabled";
        this.datefield.fireEvent("wh-refresh");
      }
      if (this.timefield)
      {
        this.timefield.disabled = this.enabled ? null : "disabled";
        this.timefield.fireEvent("wh-refresh");
      }
    }
  }


/****************************************************************************************************************************
* DOM
*/

   // Build the DOM node(s) for this component
  buildNode(data)
  {
    this.node = <t-datetime data-name={this.name} propTodd={this} title={this.hint||''} onFocusin={ evt => this._gotFocus(evt) } />;
    if(this.fieldtype == 'date' || this.fieldtype=='datetime')
    {
      this.datefield = <input type="date" class="date" placeholder={this.placeholder} data-format={this.dateformat} />;
      this.node.appendChild(this.datefield);
      this.datefield.required = data.required;
      this.datefield.disabled = this.enabled ? null : "disabled";
      this.datefield.setAttribute("data-cutoffyear", this.cutoffyear);
      this.datefield.fireEvent("wh-refresh");
    }
    if(this.fieldtype == 'datetime')
    {
      this.node.appendChild(<span>&nbsp;</span>);
    }
    if(this.fieldtype == 'time' || this.fieldtype=='datetime')
    {
      var placeholder = "00:00";
      var step = "60"; // minutes
      if (this.precision=='seconds' || this.precision=='milliseconds')
      {
        placeholder += ":00";
        step = "1"; // seconds
      }
      if (this.precision=='milliseconds')
      {
        placeholder += ".000";
        step = ".001"; // milliseconds
      }

      this.timefield = <input type="time" class="time" placeholder={placeholder} step={step} />;
      this.node.appendChild(this.timefield);
      this.timefield.required = data.required;
      this.timefield.disabled = this.enabled ? null : "disabled";
      this.timefield.fireEvent("wh-refresh");
    }
  }


/****************************************************************************************************************************
* Dimensions
*/

  calculateDimWidth()
  {
    if(!this.minwidth)
      this.minwidth = this.node.getSize().x;

    this.width.min = this.width.calc = this.minwidth;
  }

  calculateDimHeight()
  {
    this.height.min = this.height.calc = $todd.settings.grid_vsize;
  }

  relayout()
  {
  }

  // ---------------------------------------------------------------------------
  //
  // Events
  //

  _gotFocus()
  {
    this.owner.setComponentDefaultButton(this);
  }
}
