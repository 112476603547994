import * as dompack from 'dompack';
import ComponentBase from '@mod-tollium/js/component/base';

var $todd = require('@mod-tollium/web/ui/js/support');
//import Keyboard from 'dompack/extra/keyboard';


/****************************************************************************************************************************
 *                                                                                                                          *
 *  TEXT                                                                                                                    *
 *                                                                                                                          *
 ****************************************************************************************************************************/

export default class ObjText extends ComponentBase
{
/****************************************************************************************************************************
* Initialization
*/
  constructor(parentcomp, data, replacingcomp)
  {
    super(parentcomp, data, replacingcomp);

    this.componenttype = "text";
    this.labelfor = null;

    this.styles = null;
    this.transparenttoclicks = false;
    this.sethtml = false;
    this.recreatenode = false;
    this.isheading = false;
    this.ismouseselectable = false;
    this.linkactions = [];


    this.transparenttoclicks = data.transparenttoclicks;

    this.setLabelFor(data.labelfor);

    this.setStyles(data);
    this.isheading = !!data.isheading;
    this.action = data.action;

    this.ismouseselectable = data.selectable;
    this.linkactions = data.linkactions || [];

    this.setInterestingActions([this.action]);
    this.setValue(data.value, data.ishtml);
  }

  setStyles(settings)
  {
    if (!this.styles)
    {
      this.styles = { bold:      false
                    , italic:    false
                    , underline: false
                    , wordwrap:  false
                    , ellipsis:  false
                    };
    }

    Object.each(this.styles, function(value, key)
    {
      if (typeof(settings[key]) == typeof(this.styles[key]))
        this.styles[key] = settings[key];
    }, this);
  }

/****************************************************************************************************************************
* Property getters & setters
*/

  getLabelFor()
  {
    return this.labelfor;
  }

  setLabelFor(value)
  {
    this.labelfor = value;
  }

  setValue(value, ishtml)
  {
    this.value = value;
    this.sethtml = !!ishtml;
    this.buildNode();
    if(!this.styles.ellipsis)
      this.width.dirty=true;
  }


/****************************************************************************************************************************
* DOM
*/

  buildNode()
  {
    var txtnode = <t-text data-name={this.name} propTodd={this} />;

    if (this.isheading)
      txtnode.classList.add("heading");
    if(this.sethtml)
      txtnode.set('html', this.value);
    else this.value.split('\n').each(function (textrow, idx)
      {
        if(idx>0)
          txtnode.appendChild(<br />);
        txtnode.appendText(textrow);
      });

    if(this.hint)
      txtnode.set('title',this.hint);

    if(this.ismouseselectable)
      txtnode.classList.add("selectable");

    if(this.styles.bold)
      txtnode.setStyle("font-weight","bold");
    if(this.styles.italic)
      txtnode.setStyle("font-style","italic");
    if(this.styles.underline)
      txtnode.setStyle("text-decoration","underline");
    if (this.labelfor || this.action)
      txtnode.classList.add("label");
    else
    {
      if (this.styles.wordwrap)
        txtnode.classList.add("wrapped");
      if (this.styles.ellipsis)
        txtnode.classList.add("ellipsis");
    }

    if(!this.transparenttoclicks)
      txtnode.addEventListener("click", this.onClick.bind(this));

    txtnode.propTodd = this;

    this.nodesize = $todd.CalculateSize(txtnode);

    if(this.styles.ellipsis) //don't set width if ellipsis is applied
      this.nodesize.x = 0;

    if(this.node && this.node.parentNode)
    {
      this.node.parentNode.replaceChild(txtnode, this.node);
      this.node = txtnode;
    }
    else
      this.node = txtnode;
    return txtnode;
  }


/****************************************************************************************************************************
* Dimensions
*/

  calculateDimWidth()
  {
//    var width = $todd.ReadSetWidth(this.width);
//    var wrapwidth = this.styles.wordwrap || this.styles.ellipsis ? Math.max(1, width) : 0;

//    var contentwidth = $todd.CalculateTextSize(this.node.get(this.sethtml?'html':'text'), wrapwidth, this.node.getStyles($todd.GetCalculateTextStyles()), this.sethtml).x;
    this.width.calc = this.nodesize.x;
    this.width.min = this.width.calc;
  }

  applySetWidth()
  {
    this.debugLog("dimensions", "width min=" + this.width.min + ", calc=" + this.width.calc + ", set=" + this.width.set);
    this.node.setStyle("width", this.width.set);
    this.updateNodeSizeData();
  }

  getSkinSettings()
  {
    return { margintop: this.node.getStyle("margin-top").toInt()
           };
  }

  calculateDimHeight()
  {
    //this.height.min = Math.max($todd.UpToGridsize(this.node.getSize().y, $todd.settings.grid_vsize), $todd.settings.grid_vsize);
    this.height.min = Math.max(this.node.getSize().y + this.skinsettings.margintop, $todd.settings.grid_vsize);
  }

  relayout()
  {
    this.debugLog("dimensions", "relayouting set width=" + this.width.set + ", set height="+ this.height.set);
    this.node.setStyles({ width: this.width.set
                        , height: this.height.set - this.skinsettings.margintop
                        //, "margin-left": this.width.marginbefore
                        });

    if (this.styles.ellipsis)
      this.node.toggleClass('overflow', this.width.set < this.width.min || this.height.set < this.height.min);
  }


/****************************************************************************************************************************
* Events
*/

  applyUpdate(data)
  {
    switch(data.type)
    {
      case "value":
        this.setValue(data.value, data.ishtml);
        return;
    }
    super.applyUpdate(data);
  }

  onClick(event)
  {
    var anchor = dompack.closest(event.target, 'a');
    if(anchor)
    {
      var rec = this.linkactions.find(action => action.url == anchor.href);
      if (rec)
        this.owner.executeAction(rec.action);
      else if(this.isEventUnmasked("clicklink"))
        this.queueEvent(this.owner.screenname + "." + this.name, 'clicklink ' + anchor.href, true);
      else if(anchor.href.substr(0,7) == 'mailto:')
        return; //let it be, follow the link. the only exit which does _not_ event.stop...
      else if(anchor.href.substr(0,11) != 'javascript:')
        window.open(anchor.href,'_blank');

      event.preventDefault();
      event.stopPropagation();
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    if(this.action)
      this.owner.executeAction(this.action);

    var comp = this.owner.getComponent(this.labelfor);
    if (comp)
    {
      //ADDME might as well send a signal through JS to the tollium component instead of trying to click, because checkbox is now doing hacks to forward the click event
      comp.node.focus();
      comp.node.click();
    }
  }

  onTooltip(node)
  {
    if (!this.styles.wordwrap && !this.styles.ellipsis && this.width.set < this.width.calc)
      return this.node.get('text');
  }

};

exports.components = { text: $todd.ObjText
                     };
