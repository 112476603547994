var compatdragdrop = require('@mod-system/js/compat/dragdrop');


/** Try to start a drag action
    @param items
    @cell items.id
    @cell items.info
    @cell items.info.type
    @cell items.info.candownload
    @cell items.info.data
    @cell items.info.data.filename
    @cell items.info.data.mimetype
    @cell items.info.data.flags
*/
export function tryStartDrag(comp, items, event)
{
//  console.log('tryStartDrag');
  if (!items.length)
    return false;

  var infos = [];
  for (var i = 0; i < items.length; ++i)
    if (!items[i].info)
      return false;
    else
      infos.push({ type: items[i].info.type, data: items[i].info.data, id: items[i].id });

  var download = null;
  if (items.length == 1 && items[0].info.candownload)
  {
    //ADDME: rowkey?
    var url = comp.getFileTransferURL('download', { type: 'dragout', rowkey: items[0].id }, { filename: items[0].info.data.filename }).url;
    download =
        { filename:     items[0].info.data.filename
        , mimetype:     items[0].info.data.mimetype
        , url:          url
        };
  }

  var dragInfo = new compatdragdrop.DragStartData('all');
  dragInfo.localdata = { source: comp, items: infos };
  dragInfo.file = download;

//  console.log('Storing draginfo: ', dragInfo);

  dragInfo.storeIntoEvent(event);
  return true;
}
