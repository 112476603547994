import * as browser from 'dompack/extra/browser';
import Keyboard from 'dompack/extra/keyboard';

let activedragbuttons = {};

export function getDefaultDropEffect(event, effectAllowed)
{
  // Get default drop effect for allowed effects
  var dropeffect = "none";
  try
  {
    // IE denies access to dataTransfer.effectAllowed when dragging files from desktop
    switch (effectAllowed)
    {
      case "copy":           dropeffect = "copy"; break;
      case "move":           dropeffect = "move"; break;
      case "link":           dropeffect = "link"; break;
      case "copyLink":       dropeffect = "copy"; break;
      case "copyMove":       dropeffect = "move"; break;
      case "linkMove":       dropeffect = "move"; break;
      case "all":            dropeffect = "move"; break;
      case "none":           dropeffect = "none"; break;
      case "uninitialized":  dropeffect = "move"; break;
    }
  }
  catch (e)
  {
    console.log('e: ' + e);
  }

  if (Keyboard.hasNativeEventCopyKey(activedragbuttons))
    dropeffect = activedragbuttons.shiftKey ? "link" : "copy";
  else if (activedragbuttons.shiftKey)
    dropeffect = "move";

  return dropeffect;
}

export function fixupDNDEvent(event)
{
  //Sync active drag button states
  activedragbuttons = { metaKey: event.metaKey
                      , ctrlKey: event.ctrlKey
                      , shiftKey: event.shiftKey
                      , altKey: event.altKey
                      };

  /* FireFox adjusts the dropeffect based on the pressed keys. Chrome, Safari and IE don't, so just
     implement that behaviour for them. Also, override the mouse cursor in IE
  */
  if ((event.type == 'drop' || event.type.indexOf('drag') == 0) && ['chrome','safari','ie'].includes(browser.getName()))
  {
    // Set default drop effect for allowed effects
    event.dataTransfer.dropEffect = getDefaultDropEffect(event, event.dataTransfer.effectAllowed);
  }
}

