import * as components from './componentbase';
import * as dompack from 'dompack';

var domtemplate = require('@mod-system/js/dom/template');

require("../common.lang.json");

/****************************************************************************************************************************
 *                                                                                                                          *
 *  SUPPORT FUNCTIONS                                                                                                       *
 *                                                                                                                          *
 ****************************************************************************************************************************/

/* Log messages only when the given target is enabled. A user can specify targets in the URL by using the hash bookmark (e.g.
   ?$tolliumdebug=true#dimensions,communication will show messages with targets 'dimensions' and 'communication'). Currently
   the following targets are used:
   - dimensions (calculating component sizes)
   - rpc (show the individual components/messages being rfcd)
   - communication (communication between the web application and the server)
   - actionenabler (how the enabled state of actions is determined using enableons)
   - messages (the messages, updates and components being processed by the screens)
   - ui (generic UI stuff, eg focus handling)
   - all (show all messages)
*/

let enabledlogtypes = [];

var $todd = {};

$todd.settings =
{ tab_stacked_vpadding_inactive: 1 // border-bottom: 1px (only for inactive!)
, textedit_defaultwidth: 150
, list_column_padding: 8 // 2x4 padding
, list_column_minwidth: 24 // minimum width for an icon (16) + 2x4 padding
, grid_vsize: 28 //grid vertical size (28 pixels)
, tabspace_vsize: 32 //vertical size inside the tab-space layout

//size of spacers in a sync with apps.scss. SYNC-SPACERS/SYNC-SPACERS-DEBUG
, spacer_top: 10
, spacer_bottom: 10
, spacer_left: 10
, spacer_right: 10
//margin between line components. SYNC-SPACERWIDTH
, spacerwidth: 4
//size of spacers in a sync with apps.scss. SYNC-BORDERS
, border_top: 1
, border_bottom: 1
, border_left: 1
, border_right: 1

, listview_padleft: 8
, listview_padright: 8
, listview_checkboxholder_width: 20
, listview_expanderholder_width: 12 //
, listview_iconholder_width: 20     // iconholder (image 16px + margin 4px)

, fullscreen_maxx: 0.9 //maximum fraction of x width to use for fullscreen windows
, fullscreen_maxy: 1.0 //maximum fraction of y height to use for fullscreen windows

, buttonheight_intoolbar: 72
, buttonheight_intabsspace: 27

};

$todd.applications = [];
$todd.applicationstack = [];
$todd.resourcebase = "";
$todd.customactions = {};
$todd.dummyimage = null;
$todd.dummyfunction = function(){};

$todd.stopevent = function(event)
{
  event.stop();
  return false;
};

$todd.intolerant = window.location.href.indexOf('intolerant=1') != -1;
$todd.commfallback = window.location.href.indexOf('commfallback=1') != -1;
$todd.fastunload= window.location.href.indexOf('fastunload=1') != -1;

/****************************************************************************************************************************
 * Text functions
 */


// Replaces "{param_[n]}" with p[n] in str for n in [1, 4]
$todd.FormatString = function(str, p1, p2, p3, p4)
{
  return str.substitute({ param_1: p1, param_2: p2, param_3: p3, param_4: p4 });
};


/****************************************************************************************************************************
 * Layout
 */

$todd.textsize = { cache: {}
                 , node: null
                 , styles: { "font-size": ""
                           , "font-style": ""
                           , "font-weight": ""
                           , "text-decoration": ""
                           }
                 };

$todd.UpToGridsize = function (size, gridsize)
{
  if(!gridsize || gridsize<=1)
    return size;

  var remainder = size % gridsize;
  if(remainder !== 0)
    size += gridsize - remainder;
  return size;
};



$todd.ResetCachedTextSizes = function()
{
  $todd.textsize.cache = {};
};

$todd.GetCalculateTextStyles = function()
{
  return Object.keys($todd.textsize.styles);
};

$todd.CalculateSize=function(node)
{
  if (!$todd.calcsizenode)
  {
    $todd.calcsizenode = dompack.create("div", { style: { "backgroundColor": "#ffffff"
                                                        , color: "#000000"
                                                        , position: "absolute"
                                                        , visibility: "hidden" // Comment this out for debugging
                                                        , width: '1px' //Encourage content collapsing (shrink-wrap)
                                                        }});
    dompack.qS('#todd-measurements').appendChild($todd.calcsizenode);
  }
  $todd.calcsizenode.appendChild(node);
  var size = node.getSize();
  size.x = Math.ceil(size.x);
  size.y = Math.ceil(size.y);
  dompack.remove(node);
  return size;
};

// text: string with text to calculate size for
// width: maximum width in pixels for wrapping text, or 0 for no wrapping
// styles: getStyle-compatible object with font/text settings
$todd.CalculateTextSize = function(text, width, styles, ishtml)
{
  if (!$todd.textsize.node)
  {
    $todd.textsize.node = dompack.create("div", { style: { "backgroundColor": "#ffffff"
                                                         , color: "#000000"
                                                         , position: "absolute"
                                                         , visibility: "hidden" // Comment this out for debugging
                                                         }
                                                });
    dompack.qS('#todd-measurements').appendChild($todd.textsize.node);
  }

  if (typeof (text) != "string")
    text = "";
  if (typeof (width) != "number")
    width = 0;

  // Apply only the sanctioned styles
  var applystyles = $todd.textsize.styles;
  if (typeof (styles) == "object")
  {
    // merge modifies the first argument, so clone it first
    applystyles = Object.merge(Object.clone(applystyles), Object.subset(styles, $todd.GetCalculateTextStyles()));
  }

  // Check if we have calculated this before
  var key = encodeURIComponent(text) + "\t" + width + "\t" + JSON.stringify(applystyles) + "\t" + (ishtml?1:0);
  var size = $todd.textsize.cache[key];
  if (size)
    return size;

  // Set node width if specified
  if (width)
  {
    $todd.textsize.node.setStyle("width", width);
    $todd.textsize.node.setStyle("white-space", "normal");
  }
  else
  {
    $todd.textsize.node.setStyle("width", 'auto');
    $todd.textsize.node.setStyle("white-space", "nowrap");
  }

  $todd.textsize.node.setStyles(applystyles);

  // Calculate and cache text size
  var rect = $todd.textsize.node.set(ishtml ? "html" : "text", text).getBoundingClientRect();
  // Rounding up here to avoid returning rounded-down values which would result in elements too small to contain the given text
  // (getBoundingClientRect should return frational values, and not return rounded values)
  size = { x: Math.ceil(rect.right - rect.left)
         , y: Math.ceil(rect.bottom - rect.top)
         };
  $todd.textsize.cache[key] = size;
  return size;
};

$todd.ReadSize = function(sizeval)
{
  if(!sizeval)
    return null;
  if(sizeval.substr(sizeval.length-2)=='gr')
    return { type: 5, size: parseInt(sizeval, 10) };
  if(sizeval.substr(sizeval.length-2)=='px')
    return { type: 2, size: parseInt(sizeval, 10) };
  if(sizeval.substr(sizeval.length-2)=='pr')
    return { type: 1, size: parseInt(sizeval, 10) };
  if(sizeval.substr(sizeval.length-1)=='x')
    return { type: 3, size: parseInt(sizeval, 10) };
  if(sizeval=='sp')
    return { type: 4, size: 1 };
  return null;
};
$todd.IsAbsoluteParsedSize = function(size)
{
  return size&&size.type!=1;
};

// Return the set width/height, or the xml width/height, for a component's size object
$todd.ReadSetWidth = function(sizeobj)
{
  return $todd.ReadSetSize(sizeobj, true);
};
$todd.ReadSetHeight = function(sizeobj)
{
  return $todd.ReadSetSize(sizeobj, false);
};
$todd.ReadSetSize = function(sizeobj, horizontal)
{
  var size = sizeobj.new_set;
  if (size === null)
  {
    var xml = $todd.ReadSize(sizeobj.xml_set);
    size = $todd.IsFixedSize(sizeobj.xml_set) ? $todd.CalcAbsSize(xml, horizontal) : 0;
  }
  return size;
};
$todd.CalcAbsWidth = function(size)
{
  return $todd.CalcAbsSize(size, true);
};
$todd.CalcAbsHeight = function(size)
{
  return $todd.CalcAbsSize(size, false);
};
$todd.CalcAbsSize = function(size, horizontal, gridoverhead)
{
  if (!size)
    return 0;

  if (typeof(size) == "number")
    return size;

  if (typeof(size) == "string") // XML size specification
  {
    if(size.substr(size.length-2) == 'px')
      return parseInt(size, 10);
    if(size.substr(size.length-2) == 'gr')
    {
      if(horizontal)
      {
        console.error("'gr' units not supported horizontally");
        if($todd.intolerant)
          throw new Error("'gr' units not supported horizontally");
      }

      return parseInt(size, 10) * $todd.settings.grid_vsize;
    }
    if(size.substr(size.length-1) == 'x')
    {
      if (horizontal)
        return parseInt(size, 10) * $todd.desktop.x_width;
      // Round to grid size
      return $todd.UpToGridsize(parseInt(size, 10) * $todd.desktop.x_height);
    }
    if(size == 'sp')
      return $todd.settings.spacerwidth;
    return parseInt(size, 10);
  }

  if (typeof(size) == "object") // Internal size record (as returned by ReadSize)
  {
    if (size.type == 2)
      return size.size;
    if (size.type == 3)
    {
      if (horizontal)
        return size.size * $todd.desktop.x_width;
      return $todd.UpToGridsize(size.size * $todd.desktop.x_height);
    }
    if (size.type == 4)
      return $todd.settings.spacerwidth;
    if (size.type == 5)
      return size.size * $todd.settings.grid_vsize - (gridoverhead||0);
  }

  return 0;
};

$todd.IsFixedSize = function(size)
{
  return size && (size.substr(size.length-1)=='x' //matches both 'px' and 'x' :)
                  || size.substr(size.length-2)=='gr'
                  || size=='sp'
                  || ((size.toInt() + "") == size) // matches numbers in strings
                  );
};

$todd.ReadXMLSize = function(min, set, marginbefore, marginafter, iswidth)
{
  // Initialize width settings (ADDME switch all code to use xml_set_parsed?)
  return { xml_min:         iswidth ? $todd.CalcAbsWidth(min) : $todd.CalcAbsHeight(min) // min width as set by xml
         , xml_set:         set // width as set by xml (absolute or proportional size)
         , xml_set_parsed:  $todd.ReadSize(set)
         , servermin:       min //The unparsed versions. deprecate xml_min,xml_set,xml_min_parsed!
         , serverset:       set
         , marginbefore:    iswidth ? $todd.CalcAbsWidth(marginbefore) : $todd.CalcAbsHeight(marginbefore)// marginleft as set by xml
         , marginafter:     iswidth ? $todd.CalcAbsWidth(marginafter) : $todd.CalcAbsHeight(marginafter) // marginright as set by xml
         , dirty:           true // calc should be recalculated
         , min:             0 // min required width
         , calc:            0 // calculated width
         , set:             0 // allocated width
         , new_set:         null
         //, marginbefore:    0 // spacing before (combination of marginbefore and previous component's marginafter)
         };
};

//ADDME why can't we receive widths already in the proper format as much as possible?
$todd.ReadXMLWidths = function(xmlnode) //xmlnode may be null to init a default width object
{
  return $todd.ReadXMLSize(xmlnode && xmlnode.minwidth ? xmlnode.minwidth : ''
                          ,xmlnode && xmlnode.width ? xmlnode.width : ''
                          ,xmlnode && xmlnode.marginleft ? xmlnode.marginleft : ''
                          ,xmlnode && xmlnode.marginright ? xmlnode.marginright : ''
                          ,true
                          );

};
$todd.ReadXMLHeights = function(xmlnode)
{
  return $todd.ReadXMLSize(xmlnode && xmlnode.minheight ? xmlnode.minheight : ''
                          ,xmlnode && xmlnode.height ? xmlnode.height : ''
                          ,xmlnode && xmlnode.margintop ? xmlnode.margintop : ''
                          ,xmlnode && xmlnode.marginbottom ? xmlnode.marginbottom : ''
                          ,false
                          );
};

$todd.SizeDifference = function(oldsize, newsize)
{
  // Check if we got two size (compatible) objects
  if (typeof(oldsize) != "object" || typeof(oldsize.x) != "number" || typeof(oldsize.y) != "number")
    return;
  if (typeof(newsize) != "object" || typeof(newsize.x) != "number" || typeof(newsize.y) != "number")
    return;

  // Subtract oldsize from newsize
  return { x: newsize.x - oldsize.x
         , y: newsize.y - oldsize.y
         };
};

$todd.SizeEqual = function(fromsize, tosize)
{
  var diff = $todd.SizeDifference(fromsize, tosize);
  return diff && diff.x === 0 && diff.y === 0;
};


/****************************************************************************************************************************
 * Events
 */

$todd.MakeDummyDataTransfer = function(event)
{
  try
  {
    event.event.dataTransfer.setData("x-webhare/text", "tollium ist toll!");
  }
  catch (e)
  {
    event.event.dataTransfer.setData("Text", "tollium ist toll!");
  }
  event.event.dataTransfer.effectAllowed = "move";
  if (event.event.dataTransfer.setDragImage)
    event.event.dataTransfer.setDragImage($todd.dummyimage, 0, 0);
};


$todd.highpriority = false;
$todd.mousedragthreshold = 3; // Amount of pixels to move before drag kicks in
$todd.globalevents = {}; // Global event handlers

// Fallback settings for user preferences
$todd.fallback =
  { lang: "en"
  , dateformat: "%d-%m-%Y"
  , timeformat: "%H:%M"
  };

// Desktop properties, will be calculated after initialization (and on resizing/zooming)
$todd.desktop =
  { node: null
    // Dimensions
  , top: 0
  , left: 0
  , width: 0
  , height: 0
    // Orientation
  , orientation: 0          // Browser orientation (portable devices) in degrees
  , portrait: true          // If device is oriented vertically
  , landscape: false        // If device is oriented horizontally
    // x dimensions
  , x_width: 7              // The width of an 'x' character
  , x_height: 16             // The (line) height of an 'x' character
  };

$todd.uploadmethod = '';
$todd.downloadmethod = '';
$todd.tolliumservice = '';


/****************************************************************************************************************************
 * Window events
 */

$todd.globalevents.OnUnload = function(event)
{
  // prepare transportmgr for unload
  $todd.transportmgr.prepareForUnload();

  // Let every app send their shutdown message
  $todd.applicationstack.forEach(function(app) { app.queueUnloadMessage(); });
  $todd.transportmgr.executeUnload();

  $todd.transportmgr.destroy();
};

$todd.mouse = { clickstatus: null
              , hoverstatus: { tooltipshowtimeout: null
                             , tooltiphidetimeout: null
                             , curcomp: null
                             , dragcomp: null
                             }
              , dragstatus: null
              };

/* comp The source object (dragging from)
   obj The element which is dragged
   limits {left,right,top,bottom} limits for dragging
   cursor Not supported (yet)
*/
$todd.startMouseDrag = function(comp, obj, limits, cursor)
{
  $todd.mouse.dragstatus = { source: comp instanceof components.ToddCompBase ? comp : null
                           , obj: obj
                           , startpos: null
                           , limits: limits
                           , active: false
                           , reposition_timeout: null
                           };
//  if (cursor)
//    document.body.style.cursor = cursor + " !important";
};

$todd.globalevents.OnDragFiles = function(event)
{
  // We want to handle this ourselves
  event.preventDefault();
};

$todd.globalevents.OnDropFiles = function(event)
{
  console.error(event);
  // We want to handle this ourselves
  event.preventDefault();

  if (event.dataTransfer.files.length)
  {
    // Lookup the todd component dropped onto
    var comp = $todd.globalevents.GetEventComponent(event);
    if (comp && comp.acceptfiles)
    {
      console.log("Firing dropfiles event on '" + comp.name + "' for " + event.dataTransfer.files.length + " files");
      //ADDME: There should be some general file uploader which handles uploading the dropped files to the server
      comp.fireEvent("dropfiles", event);
    }
  }
};

// all_todd == false: only return target with ObjLayout-derived todd object
$todd.globalevents.GetEventComponent = function(event, all_todd)
{
  // Look up in the DOM tree until we find an element with an associated todd object
  var target = event.target;
  while (target)
  {
    var comp = target.retrieve ? target.propTodd : null;
    if (comp && (all_todd || comp instanceof components.ToddCompBase))
      return comp;
    target = target.parentNode;
  }
  return null;
};

/****************************************************************************************************************************
 * Globally unique id's
 */

$todd.globalidcounter = 0;
$todd.getGlobalId = function()
{
  return (++$todd.globalidcounter).toString(16); //FIXME deprecate
};


/****************************************************************************************************************************
 * Some experimental and implementation test functions
 */

$todd.componentsToMessages=function(components)
{
  /* ADDME: updateScreen is currently an attempt at a 'prettier' API for screen management but we should probably merge with processMessages eventually (perhaps todd controller should change its format)
   */
  var messages=[];
  Object.each(components, function(obj,name)
    {
      if(!obj.messages || Object.keys(obj).length>1) //not only sending messages
      {
        var compmsg = Object.clone(obj);
        compmsg.instr = "component";
        compmsg.target = name;
        compmsg.type = obj.type || '-shouldalreadyexist';
        compmsg.name = name;
        compmsg.width = obj.width;
        compmsg.height = obj.height;
        compmsg.minwidth = obj.minwidth;
        compmsg.minheight = obj.minheight;
        compmsg.enabled = obj.enabled!==false;
        delete compmsg.messages;
        messages.push(compmsg);
      }
      if(obj.messages)
        obj.messages.each(function(msg)
        {
          var copymsg = Object.clone(msg); //FIXME copying is probably overkill, but i'm trying to avoid touching source objects.. need to better align various snyatxes

          copymsg.msg = 'message';
          copymsg.target = name;
          messages.push(copymsg);
        });
    });
  return messages;
};

$todd.instantiateTemplate = function(templid, inserts)
{
  var templ = document.getElementById(templid);
  if(!templ)
    throw new Error("No such template '" + templid + "'");

  var node = domtemplate.importTemplate(document, templ);
  node=node.firstElementChild;
  if(node.nextElementSibling)
    throw new Error("Template '" + templid + "' has cloned to more than one node");

  var nodes = { root: node };
  if (inserts)
  {
    Array.each(inserts, function(insertpoint)
    {
      var candidate = node.querySelector("." + insertpoint + ",." + templid + "__" + insertpoint);
      if(!candidate)
        throw new Error("Template '" + templid + "' does not contain expected insertion point '" + insertpoint + "'");

      nodes[insertpoint] = candidate;
    });
  }
  return nodes;
};

$todd.IsDebugTypeEnabled = function(type)
{
  return enabledlogtypes.includes('all') || enabledlogtypes.includes(type);
};

$todd.DebugTypedLog = function(target)
{
  var type;
  if (typeof(target) == "string")
  {
    target = target.split(":");
    type = target[0];
    if (target.length > 1)
      target = target[1];
  }

  if (typeof(target) != "string" || !([ "log", "info", "warn", "error" ].includes(target)))
    target = "log";

  // Check if the requested type should be shown
  if (!$todd.IsDebugTypeEnabled(type))
    return;

  // Strip first element (type) from arguments
  var args = Array.prototype.slice.call(arguments);
  args.splice(0, 1);
  console[target].apply(console, args);
};

function checkLogTypes()
{
  // Check for specific debug log types (see DebugTypedLog)
  if (document.location.hash)
  {
    var hash = document.location.hash.substr(1);
    enabledlogtypes = hash.split(",");
  }

  if (enabledlogtypes.includes('all'))
    console.warn("Showing all typed debug messages");
  else if (enabledlogtypes.length)
  {
    console.warn("Showing typed debug messages with types " + enabledlogtypes.join(", "));
    document.documentElement.toggleClass('debug-grid', enabledlogtypes.contains('grid'));
  }
}

// The CSS Color Module Working Draft[1] defines hex notation for RGB color with an alpha value, but these are not supported
// by (all?) browser (yet?). This functions rewrites them to rgba() notation.
// [1] https://drafts.csswg.org/css-color/#hex-notation
$todd.fixupColor = function(color)
{
  if (color.match(/\#[0-9a-z]{8}$/))
  {
    return "rgba(" + parseInt(color.substr(1, 2), 16) + ","
         + parseInt(color.substr(3, 2), 16) + ","
         + parseInt(color.substr(5, 2), 16) + ","
         + (parseInt(color.substr(7, 2), 16) / 255) + ")";
  }
  if (color.match(/\#[0-9a-z]{4}$/))
  {
    return "rgba(" + parseInt(color.substr(1, 1) + color.substr(1, 1), 16) + ","
         + parseInt(color.substr(2, 1) + color.substr(2, 1), 16) + ","
         + parseInt(color.substr(3, 1) + color.substr(3, 1), 16) + ","
         + (parseInt(color.substr(4, 1) + color.substr(4, 1), 16) / 255) + ")";
  }
  return color;
};

module.exports = $todd;
window.__todd = $todd; //test framework currently requires it. FIX THAT

checkLogTypes();
window.addEventListener("hashchange", checkLogTypes);
