/* global DOMEvent */ // needs to be global, because we redefine it
import * as whintegration from '@mod-system/js/wh/integration';
import * as browser from 'dompack/extra/browser';
import { $, $$, Element, Elements, Class, Type, Browser, Fx } from "@mod-system/js/frameworks/mootools/core";


/* This file is always included when wh.compat.base is requested. It only contains non-browser stuff, like generic object
   extensions. All browser stuff, like Element extensions, is located in base-browser.js */

module.exports = { };

function isHTMLElement (node)
{
  return node.nodeType == 1 && typeof node.className == "string";
}

module.exports.Event = new Class(
{ bubbles: true
, cancelable: true
, defaultPrevented: false
, target:null
, type:null

, initEvent:function(type, bubbles, cancelable)
  {
    this.type = type;
    this.bubbles = bubbles;
    this.cancelable = cancelable;
  }
, stopPropagation: function()
  {
    this.bubbles=false;
  }
, preventDefault:function()
  {
    this.defaultPrevented=true;
  }
, stop:function()
  {
    this.preventDefault();
    this.stopPropagation();
  }
});

module.exports.getActiveElement = function(doc)
{
  try
  {
    //activeElement can reportedly throw on IE9 and _definately_ on IE11
    return doc.activeElement;
  }
  catch(e)
  {
    return null;
  }
};


var ispreview = false;
module.exports.__transform_venderprefix = '';
module.exports.__transform_property = '';

var whconfigel=document.querySelector('script#wh-config');
if(whconfigel)
{
  module.exports.config=Object.merge(module.exports.config||{}, JSON.parse(whconfigel.textContent));
  // Make sure we have obj/site as some sort of object, to prevent crashes on naive 'if (module.exports.config.obj.x)' tests'
  if(!module.exports.config.obj)
    module.exports.config.obj={};
  if(!module.exports.config.site)
    module.exports.config.site={};
}
else if(!module.exports.config) //no module.exports.config is no <webdesign>. don't bother with obj & site, but designfiles still rely on module.exports.config itself
{
  module.exports.config={};
}

function getAndroidVersion(ua)
{
  ua = ua || navigator.userAgent;
  var match = ua.match(/Android\s([0-9\.]*)/);
  return match ? match[1] : false;
}

function initializeWHBaseBrowser()
{
  module.exports.BrowserFeatures =
    { trueopacity:   false
    , pointerevents: false
    , animations:    false

    , android_positionfixedbroken: Browser.platform == "android" && parseInt(getAndroidVersion(),10) <= 3 //pre-4 is apparently buggy with position:fixed & translations
    };

  var element = document.createElement('x');
  element.style.cssText = 'pointer-events:auto;';
  if(element.style.pointerEvents === 'auto')
    module.exports.BrowserFeatures.pointerevents = true;

  module.exports.BrowserFeatures.trueopacity = typeof element.style.opacity !== "undefined";

  var previewvar = location.href.match(/[\?&#]whs-clock=([^&#?]*)/);
  if(previewvar)
  {
    document.documentElement.addClass("wh-preview");
    ispreview=true;
  }

  var transforms = {
    computed: ['transformProperty', 'transform', 'WebkitTransform', 'MozTransform', 'msTransform'],
    prefix: ['', '', '-webkit-', '-moz-', '-ms-']
  };
  var testEl = new Element("div");
  transforms.computed.some(function(el, index)
  {
    var test = el in testEl.style;
    if (test)
    {
      module.exports.__transform_venderprefix = transforms.prefix[index];
      module.exports.__transform_property = transforms.computed[index];
    }
    return test;
  });
  module.exports.BrowserFeatures.animations = "webkitAnimation" in testEl.style || "animation" in testEl.style;

}

//workaround for first rect( parameter not animating
Fx.CSS.Parsers.Rect={
               parse: function(value){
                      if (value.substr(0,5)=='rect(')
                            return parseFloat(value.substr(5));
                        return false;
               },
               compute: Fx.compute,
               serve: function(value){
                      return 'rect(' + value + 'px';
               }
        };


Element.implement(
{ getSelfOrParent: function(selector)
    {
      return this.match(selector) ? this : this.getParent(selector);
    }
});


// Internet Explorer still uses an old prefixed name for the node.matches() method
// (and just to be nice whe'll also support other old browser.. for now.)
// http://caniuse.com/#feat=matchesselector
if (!Element.prototype.matches)
{
  var ep = Element.prototype;

  if (ep.webkitMatchesSelector) // Chrome <34, SF<7.1, iOS<8
    ep.matches = ep.webkitMatchesSelector;

  if (ep.msMatchesSelector) // IE9/10/11 & Edge
    ep.matches = ep.msMatchesSelector;

  if (ep.mozMatchesSelector) // FF<34
    ep.matches = ep.mozMatchesSelector;
}


// http://www.nixtu.info/2013/06/how-to-upload-canvas-data-to-server.html
module.exports.dataURItoBlob = function(dataURI)
{
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var dw = new DataView(ab);
  for(var i = 0; i < byteString.length; i++)
  {
    dw.setUint8(i, byteString.charCodeAt(i));
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ ab ], { type: mimeString });
};

// canvas.toBlob polyfill
if (window.HTMLCanvasElement && !window.HTMLCanvasElement.prototype.toBlob)
{
  window.HTMLCanvasElement.prototype.toBlob = function(callback, type, quality)
  {
    callback(module.exports.dataURItoBlob(this.toDataURL(type, quality)));
  };
}


// NOTE: Safari currently doesn't allow input in <input> elements while in fullscreen mode
// keyboard events however still work, although you need to set focus again
// ADDME: also help with fullscreenchange events?
if (!window.requestFullScreen)
{
  Element.implement(
  { requestFullScreen: Element.prototype.webkitRequestFullScreen || Element.prototype.mozRequestFullScreen || Element.prototype.msRequestFullscreen || function() {}
  });
  /*
  { requestFullScreen:
        function()
        {
          if (this.webkitRequestFullScreen)
          {
            this.webkitRequestFullscreen();//this.ALLOW_KEYBOARD_INPUT);
            console.log("okliedoklie");
          }
          else if (this.mozRequestFullScreen)
            this.mozRequestFullScreen();
          else
            this.msRequestFullScreen();
        }
  });
  */

  // cancelFullScreen is implemented only on document (not documented in MDN)
}

if (!document.exitFullscreen)
  document.exitFullScreen = document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msCancelFullScreen || document.exitFullscreen || function() {};

Element.implement(
{ toggleFullScreen: function()
  {
    var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;

    // if we aren't in fullscreen or we want to toggle another element to go fullscreen
    // request full screen on that element
    if (fullscreenElement != this)
      this.requestFullScreen();
    else
      document.exitFullScreen();
  }
});


module.exports.getIframeDocument = function(iframe)
{
  // if we try to read contentWindow before the iframe has been inserted into a document,
  // IE will throw an 'Unspecified error'
  if (typeof iframe.contentWindow == 'unknown')
    return null;

  // contentDocument is supported by NS6, Firefox, Opera, IE8
  if (iframe.contentDocument)
    return iframe.contentDocument;

  // FF3/SF3.2.1/OP9.64/CHR1 will properly return null (typeof=='object') if not initialized
  if (iframe.contentWindow == null)
    return null;

  //if (iframe.document) // For IE5
  //  return iframe.document;

  // if we have a contentwindow we can safely ask for it's document
  // (IE5.5 and IE6)
  return iframe.contentWindow.document;
};

//we cannot use location.hash safely, as the url http://b-lex.nl/#motoren%25abc will return 'motoren%abc' on FF, and 'motoren%25abc' on Chrome
module.exports.getCurrentHash = function()
{
  var hashidx = location.href.indexOf('#');
  if(hashidx==-1)
    return '';
  return decodeURIComponent(location.href.substr(hashidx+1));
};

/* eslint-disable no-inner-declarations */

/*globals HTMLSelectElement*/
/**
* @requires polyfill: Array.from {@link https://gist.github.com/4212262}
* @requires polyfill: Array.prototype.filter {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter}
* @requires polyfill: Object.defineProperty {@link https://github.com/eligrey/Xccessors}
* @license MIT, GPL, do whatever you want
*/
  if(!new Element("select").selectedOptions)
  {
    function PseudoHTMLCollection(arr)
    {
      var i, arrl;
      for (i = 0, arrl = arr.length; i < arrl; i++)
          this[i] = arr[i];

        try {
            Object.defineProperty(this, 'length', {
                get: function () {
                    return arr.length;
                }
            });
        }
        catch (e) { // IE does not support accessors on non-DOM objects, so we can't handle dynamic (array-like index) addition to the collection
            this.length = arr.length;
        }
        if (Object.freeze) { // Not present in IE, so don't rely on security aspects
            Object.freeze(this);
        }
    }

    PseudoHTMLCollection.prototype = {
        constructor: PseudoHTMLCollection,
        item: function (i) {
            return this[i] !== undefined && this[i] !== null ? this[i] : null;
        },
        namedItem: function (name) {
            var i, thisl;
            for (i = 0, thisl = this.length; i < thisl; i++) {
                if (this[i].id === name || this[i].name === name) {
                    return this[i];
                }
            }
            return null;
        }
    };

    Object.defineProperty(HTMLSelectElement.prototype, 'selectedOptions', {get: function () {
        return new PseudoHTMLCollection(Array.convert(this.options).filter(function (option) {
            return option.selected;
        }));
    }});
  }

  /*
  original from http://my.opera.com/emoller/blog/2011/12/20/requestanimationframe-for-smart-er-animating
  Fixed by B-Lex

  Native support in:
  - Chrome 17
  - IE 10
  - FF 4 (incomplete, -moz)
  - FF 11 (-moz)
  - Safari unknown when support (supported in Webkit nightlies)
  - Opera 15

  Without prefix in
  - IE 10
  - FF 23
  - CHR 24
  - SF 6.1 / iOS 7
  - OP 15
  */
  var reqanim_prefix;
  var reqanim_currentframe = 0;

  var vendors = ['ms', 'moz', 'webkit', 'o'];

  for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x)
  {
    if (typeof( window[vendors[x]+'RequestAnimationFrame'] ) != "function")
      continue;

    reqanim_prefix = vendors[x];

    // Firefox 4+ has supported for mozRequestAnimationFrame, but didn't return requestId before Firefox 11
    window.requestAnimationFrame =
      function(callback) // 2nd argument is only supported by Webkit(SF/CHR)
      {
        var requestId = window[reqanim_prefix+'RequestAnimationFrame'](callback);

        if (typeof requestId == "undefined") // FIX for FF4 up to FF10
        {
          reqanim_currentframe++;
          requestId = reqanim_currentframe;
        }

        return requestId;
      };

      window.cancelAnimationFrame =
        window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
  }

  /// Safari seems to immediately repeat keydown events when pressing Esc, so we'll cancel subsequent keydown events
  /// WebKit bug report: https://bugs.webkit.org/show_bug.cgi?id=78206
  if (browser.getName()=="safari")
  {
    (function()
    {
      // Should keydown events be cancelled? (We'll allow repeating events after repeatTimeout)
      var startCancelling = true;
      // Are keydown events being cancelled?
      var cancelEvents = false;
      // Keyboard repeat timeout
      var repeatTimeout = 500;

      window.addEventListener("keydown", function(event)
      {
        // Check if esc is pressed without modifier keys
        if (event.keyCode == 27 && !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey)
        {
          if (cancelEvents)
          {
            // Cancel this event
            event.stopPropagation();
            event.preventDefault();
          }
          else if (startCancelling)
          {
            // Start cancelling events
            startCancelling = false;
            cancelEvents = true;
            // Stop cancelling events after repeatTimeout
            setTimeout(function()
            {
              cancelEvents = false;
            }, repeatTimeout);
          }
        }
      }, true);
      window.addEventListener("keyup", function(event)
      {
        // Check if esc is pressed without modifier keys
        if (event.keyCode == 27)
        {
          // Stop cancelling events; they may be cancelled upon next esc keydown
          startCancelling = true;
          cancelEvents = false;
        }
      }, true);
    })();
  }

//Fire a layout change event
module.exports.fireLayoutChangeEvent = function(node, direction)
{
  node=$(node);

  // FIXME: is there still code listening to these legacy classes??
  if(module.exports.legacyclasses !== false)
  {
    node.getParents('.-wh-layoutlistener').fireEvent("-wh-layoutchange", { target:node, direction:'up' });
    if(node.hasClass('-wh-layoutlistener'))
      node.fireEvent("-wh-layoutchange", { target:node, direction:'self' });
    node.getElements(".-wh-layoutlistener").fireEvent("-wh-layoutchange", { target:node, direction:'down' });
  }

  var up = !direction || direction == "up";
  var down = !direction || direction == "down";
  //  console.log( up ? "UP":"", down ? "DOWN":"", node);
  //console.trace();

  //console.log("LayoutChange " + (up?"UP":"") + " " + (down?"DOWN":"") + " from", node);

  if (up)
    node.getParents(".wh-layoutlistener").fireEvent("wh-layoutchange", { target: node, direction: "up" });

  if(node.hasClass("wh-layoutlistener"))
    node.fireEvent("wh-layoutchange", { target: node, direction:'self' });

  if (down)
    node.getElements(".wh-layoutlistener").fireEvent("wh-layoutchange", { target: node, direction: "down" });

  window.fireEvent("wh-layoutchange", { target: node, direction: "down" });
};

module.exports.getJSONAttribute = function(node, attrname)
{
  var data = node.getAttribute(attrname) || 'null';
  if (data.substr(0,1) == '@')
  {
    // This is a reference to a variable in window.
    // It is meant for reuse of settings (for example for RTE or slideshow settings)
    var path = data.substr(1).split('.');
    path.unshift(window);
    var curr = window;

    for (var i = 1; i < path.length; ++i)
    {
      var pathelt = path[i];
      var sub = curr[pathelt];
      if (typeof sub == "undefined")
        throw Error("Cannot find variable '" + pathelt + "' in '" + path.slice(0,i).join(',') + "' when looking up '" + data + "'");
      curr = sub;
    }
    data = curr;
  }
  else
    data = JSON.parse(data);

  return data;
};

module.exports.getJSAssetPath = function(scriptname)
{
  var url;
  var script = $("wh-designfiles-combined-js");
  if (script)
    url = script.getAttribute("src");

  if (!url)
  {
    var playerscript = $$('script[src*="/' + scriptname + '"]').pick();
    if (playerscript)
      url = playerscript.getAttribute("src");
  }
  if(url)
  {
    var urlparts = url.split('?')[0].split('/');
    return urlparts.slice(0,urlparts.length-1).join('/')+'/';
  }
  return null;
};

function generateForm(action, values, method)
{
  var form = new Element("form", { action: action, method: method || "POST", charset: "utf-8" });
  if(values instanceof Array)
  {
    Array.each(values, function(item)
    {
      form.adopt(new Element("input", { type: "hidden", name: item.name, value: item.value }));
    });
  }
  else Object.each(values, function(value, name)
  {
    form.adopt(new Element("input", { type: "hidden", name: name, value: value }));
  });
  return form;
}

module.exports.submitForm = function(action, values, method)
{
  var form = generateForm(action, values, method);
  $(document.body).adopt(form);
  form.submit();
};

/** get elements matching a selector, matching them from the documentNode (getElement only applies to subelements. use this if you want '#xx span' to match nodes if you start the search at #xx)
    @param node: if null, match from top. if Element, iterate element and children. if rangestart/rangelimit are set, walk through that range, excluding rangelimit
*/
module.exports.getTopMatchedElements = function(node, selector)
{
  function getSelfAndElements(node,selector)
  {
    // NOTE: we convert the static nodelist to a MooTools element list
    var retval = $$( node.querySelectorAll(selector) );
    if (node.matches(selector))
      retval.include( node );

    return retval;
  }

  var elements=[];
  if(!node)
  {
    // if no start/root node is specified, get all elements within the document which match the selector
    elements = document.querySelectorAll(selector);
  }
  else if(isHTMLElement(node))
  {
    elements = getSelfAndElements($(node), selector);
  }
  else if(node.rangestart)
  {
    for(var testnode = node.rangestart;testnode&&testnode!=node.rangelimit;testnode=testnode.nextSibling)
      if(module.exports.isHTMLElement(testnode))
        elements.append( getSelfAndElements($(testnode), selector));
  }
  else if(node.firstChild) //document fragment
  {
    for(node = node.firstChild;node;node=node.nextSibling)
      if(node.getElements)
        elements.append(getSelfAndElements(node));
  }
  return elements;
};

//Hook fireEvent so we can integrate our 'wh-after-domready ' '
var saveFireEvent = window.fireEvent;
window.fireEvent = function(type, args, delay)
{
  saveFireEvent.apply(this, [type, args, delay]);
  if(type=="domready" || type=="load")
    saveFireEvent.apply(this, ["wh-after-" + type, args, delay]);
  return this;
};

/// Chain a function after the DOMEvent constructor
var domextends = null;
module.exports.extendDOMEventConstructor = function(func)
{
 if (!domextends)
  {
    domextends = [];

    // Replace the DOMEvent type by our own implementation - copy prototype & keys over from the old DOMEvent
    var oldDOMEvent = DOMEvent;
    /* eslint-disable no-global-assign */
    DOMEvent = new Type('DOMEvent', function()
    {
      oldDOMEvent.apply(this, arguments);
      for (var i = 0; i < domextends.length; ++i)
        domextends[i].apply(this, arguments);
    });
    DOMEvent.prototype = oldDOMEvent.prototype;
    Object.keys(oldDOMEvent).each(function(key, value){ DOMEvent[key] = oldDOMEvent[key]; });
  }

  domextends.push(func);
};

module.exports.getDocumentLanguage = function()
{
  var htmlnode = document.documentElement;
  return htmlnode ? htmlnode.getAttribute('lang') || htmlnode.getAttribute('xml:lang') || 'en' : 'en';
};

//overhead calculations
module.exports.getHorizontalOverhead = function(node)
{
  if (typeof window["getComputedStyle"] == "function")
  {
    let style = getComputedStyle(node, null);
    return (style.getPropertyValue("padding-left").toInt() || 0)
           + (style.getPropertyValue("padding-right").toInt() || 0)
           + (style.getPropertyValue("border-left-width").toInt() || 0)
           + (style.getPropertyValue("border-right-width").toInt() || 0);
  }
  else if (node["currentStyle"])
  {
    let style = node.currentStyle;
    return (style.paddingLeft.toInt() || 0)
           + (style.paddingRight.toInt() || 0)
           + (style.borderLeftWidth.toInt() || 0)
           + (style.borderRightWidth.toInt() || 0);
  }
  return 0;
};

module.exports.getVerticalOverhead = function(node)
{
  if (typeof window["getComputedStyle"] == "function")
  {
    let style = getComputedStyle(node, null);
    return (style.getPropertyValue("padding-top").toInt() || 0)
           + (style.getPropertyValue("padding-bottom").toInt() || 0)
           + (style.getPropertyValue("border-top-width").toInt() || 0)
           + (style.getPropertyValue("border-bottom-width").toInt() || 0);
  }
  else if (node["currentStyle"])
  {
    let style = node.currentStyle;
    return (style.paddingTop.toInt() || 0)
           + (style.paddingBottom.toInt() || 0)
           + (style.borderTopWidth.toInt() || 0)
           + (style.borderBottomWidth.toInt() || 0);
  }
  return 0;
};

// get the information needed to properly stretch an image
// (to fully cover (fit or fill) the available space (outwidth/outheight) with the original size (inwidth/inheight) while keeping the aspect ratio)
module.exports.getCoverCoordinates = function(inwidth, inheight, outwidth, outheight, fit)
{
  var infx = !(outwidth > 0);
  var infy = !(outheight > 0);
  var dx = infx ? 0 : inwidth / outwidth;
  var dy = infy ? 0 : inheight / outheight;
  var scale;
  if(infx)
    scale=dy;
  else if(infy)
    scale=dx;
  else if(fit)
    scale = Math.max(dx,dy);
  else
    scale = Math.min(dx,dy);

  return { width: inwidth/scale
         , height: inheight/scale
         , top: (outheight - (inheight/scale))/2
         , left: (outwidth - (inwidth/scale))/2
         };
};

module.exports.dispatchDomEvent=function(element, eventtype, options)
{
  if(!options)
    options={};
  if(!("cancelable" in options)) //you generally need to think about these two...
    console.error("You should set 'cancelable' to true or false in a module.exports.dispatchDomEvent call");
  if(!("bubbles" in options))
    console.error("You should set 'bubbles' to true or false in a module.exports.dispatchDomEvent call");

  //Firefox Bugzilla #329509 - Do not prevent event dispatching even if there is no prescontext or (form) element is disabled
  //we'll just normalize around Firefox' behaviour - IE might do it too?
  if(element.disabled)
    return true;

  var evt = element.ownerDocument.createEvent(eventtype == "click" ? "MouseEvents" : "HTMLEvents");
  evt.initEvent(eventtype, options.bubbles, options.cancelable);

  if(options.detail)
    evt.detail = options.detail;

  if(eventtype=='click' && window.IScroll)
    evt._constructed = true; //ensure IScroll doesn't blindly cancel our synthetic clicks

  var result = element.dispatchEvent(evt);
  return result;
};

//manually fire 'onchange' events. needed for event simulation and some IE<=8 modernizations
module.exports.fireHTMLEvent=function(element, type)
{
  //http://stackoverflow.com/questions/2856513/trigger-onchange-event-manually
  return module.exports.dispatchDomEvent(element, type, { bubbles: ["input","change","click"].contains(type), cancelable: true});
};

module.exports.setTextWithLinefeeds = function(node, message)
{
  Array.each(message.split("\n"), function(line,idx)
  {
    if(idx==0)
      node.set("text",line);
    else
      node.adopt(new Element("br")).appendText(line);
  });
};

//A DOM4 customevent. http://www.w3.org/TR/dom/#interface-customevent
module.exports.CustomEvent = new Class(
{ Extends: module.exports.Event
, initialize:function(type, eventInitDict)
  {
    this.bubbles = eventInitDict && eventInitDict.bubbles;
    this.cancelable = eventInitDict && eventInitDict.cancelable;
    this.detail = (eventInitDict ? eventInitDict.detail : null) || null;
    this.type = type;
  }
});

module.exports.dispatchEvent = function(node, event)
{
  if(!node)
    throw new Error("null node passed to dispatchEvent");

  event.target = node;

  //one day, we should match this algorithm exactly: http://www.w3.org/TR/dom/#dispatching-events
  var mywindow = node.ownerDocument.defaultView;
  var nodetree = [node];
  while(node.parentNode)
  {
    node = node.parentNode;
    nodetree.unshift(node);
  }
  if(mywindow)
    nodetree.unshift(mywindow);

  //ADDME capture phase? but only if the event was declared as native though - addEvent cannot officially capture such events anyway.

  //bubble!
  for(var treepos=nodetree.length-1;treepos >= 0;--treepos)
  {
    node = $(nodetree[treepos]);
    if(!node.fireEvent)
      continue;
    node.fireEvent(event.type, event);
    if(!event.bubbles) //stop looping once bubble is broken
      break;
  }
  return !event.defaultPrevented;
};

/** Change the value of a form element, and fire the correct events as if it were a user change
    @param element Element to change
    @param newvalue New value
    @param norecursecheck Optional. if true, disable recursive change checking */
module.exports.changeValue = function(element, newvalue, norecursecheck)
{
  if(element instanceof Array || element instanceof Elements)
  {
    Array.each(element, function(node) { module.exports.changeValue(node, newvalue); });
    return;
  }

  element=$(element);

  if(element.nodeName=='INPUT' && ['radio','checkbox'].contains(element.type))
  {
    if(!!element.checked == !!newvalue)
      return;
    element.checked=!!newvalue;
  }
  else
  {
    if(element.value == newvalue)
      return;

    element.value = newvalue;

  }

  if(!norecursecheck && module.exports.changeValue.changelist.contains(element))
  {
    console.error("Changing element ",element,"to",newvalue," while its onchange is firing");
    throw new Error("module.exports.changeValue detected recursion on element");
  }

  try
  {
    if(!norecursecheck)
      module.exports.changeValue.changelist.push(element);

    module.exports.fireHTMLEvent(element,"input");
    module.exports.fireHTMLEvent(element,"change");
  }
  finally
  {
    if(!norecursecheck)
      module.exports.changeValue.changelist.erase(element);
  }
};

module.exports.changeValue.changelist = [];


/// Always set the cancelBubble flag, so we can detect that stopPropagation was called
var stopPropagation = DOMEvent.prototype.stopPropagation;
DOMEvent.prototype.stopPropagation = function()
{
  stopPropagation.call(this);
  if (this.event.stopPropagation)
    this.event.cancelBubble = true;
  return this;
};

initializeWHBaseBrowser();

function runCompat()
{
  //Firefox redisables buttons that were disabled at refresh, even if they're not disabled in the HTML
  if (Browser.name == "firefox")
    $$('button:disabled').set('disabled',null);
}

module.exports.isPreview = function()
{
  return ispreview;
};

//FIXME NOTE external dependencies, but possibly not on the createBusyLock part. this function is communicated as part of paymentproviders example. do not break API for a long time!
exports.executeSubmitInstruction = whintegration.executeSubmitInstruction;

window.addEvent("domready", runCompat);
