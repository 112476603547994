// Auto-generated RPC interface from /opt/wh/whtree/modules/tollium/js/internal/todd.rpc.json
var JSONRPC = require("@mod-system/js/net/jsonrpc");
var request = exports.rpcObject = new JSONRPC();
exports.rpcResolve = function(promise, result) { request._doAsyncAbort(promise, result) };
exports.rpcReject = function(promise, reject) { request._doAsyncAbort(promise, null, reject) };
var urlappend = '';
if(self&&self.location)
{
  var urldebugvar = window.location.href.match(new RegExp('[?&#]wh-debug=([^&#?]*)'));
  if(urldebugvar)
    urlappend='?wh-debug='+urldebugvar[1];
}
var options = exports.rpcOptions = {};

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/webhare/modules/tollium/lib/todd/internal/service.whlib'

exports.runToddComm = exports.RunToddComm = /*RECORD*/function(/*RECORD*/ request)
{
var opts={url:"/wh_services/tollium/todd"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("RunToddComm",Array.prototype.slice.call(arguments),opts);
}

exports.retrieveImages = exports.RetrieveImages = /*RECORD*/function(/*RECORD ARRAY*/ images, /*BOOLEAN*/ nocache, /*BOOLEAN*/ nobroken, /*BOOLEAN*/ rewritestyles)
{
var opts={url:"/wh_services/tollium/todd"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("RetrieveImages",Array.prototype.slice.call(arguments),opts);
}

exports.getWebdavOpenInfo = exports.GetWebdavOpenInfo = /*RECORD*/function(/*STRING*/ pathname, /*STRING*/ item, /*RECORD*/ data)
{
var opts={url:"/wh_services/tollium/todd"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("GetWebdavOpenInfo",Array.prototype.slice.call(arguments),opts);
}
