import '../css/webinterface.scss';
require('roboto-fontface');
require('font-awesome/css/font-awesome.css');
require('@mod-wrd/js/auth');

import * as dompack from 'dompack';

const IndyShell = require('@mod-tollium/web/ui/js/shell');

if(document.documentElement.classList.contains('wh-tollium--app'))
{
  if(!document.all && ("max" in document.createElement("progress")) && !document.documentElement.classList.contains("previewframe")) //IE < 11
  {
    window.$shell = new IndyShell;
  }
}
else if(window.parent && document.documentElement.hasClass("previewframe")) //plain preview interface
{
  if(window.parent.suggestRenderingPDF)
  {
    let whpdfnode = document.querySelector('wh-pdf');
    if(whpdfnode)
      window.parent.suggestRenderingPDF(whpdfnode.getAttribute("url"));
  }
}
