import * as dompack from 'dompack';
import { qSA } from 'dompack';

export function fixupScopeTRs(node)
{
  for(let tr of qSA(node, 'tr'))
  {
    let scoperow = !!tr.querySelector('th[scope=row]');
    dompack.toggleClass(tr, 'wh-rtd--hasrowheader', scoperow);

    let scopecol = !!tr.querySelector('th[scope=col]');
    dompack.toggleClass(tr, 'wh-rtd--hascolheader', scopecol);
  }
}
