import * as dompack from 'dompack';
import HTMLComponentBase from '@mod-tollium/js/component/html';
var $todd = require('@mod-tollium/web/ui/js/support');
import { replaceRangeComponent } from '@mod-tollium/web/ui/components/basecontrols/slider';

/****************************************************************************************************************************
 *                                                                                                                          *
 *  SLIDER                                                                                                                  *
 *                                                                                                                          *
 ****************************************************************************************************************************/

export default class ObjSlider extends HTMLComponentBase
{

/****************************************************************************************************************************
 * Initialization
 */

  constructor(parentcomp, data, replacingcomp)
  {
    super(parentcomp, data, replacingcomp);
    this.componenttype = "slider";
    this.min = data.min;
    this.max = data.max;
    this.step = data.step;
    this.orientation = data.orientation;
    this.wrapinlineblock = true;

    this.buildNode();
    this.setValue(data.value);
    this.setRequired(data.required);
    this.setEnabled(data.enabled);
  }

/****************************************************************************************************************************
* DOM
*/

  // Build the DOM node(s) for this component
  buildHTMLNode(data)
  {
    this.inputnode = dompack.create("input", {
                                  "type"   :  "range"
                                , "min"    : this.min
                                , "max"    : this.max
                                , "step"   : this.step
//                                , "orient" : (this.orientation ? this.orientation : 'horizontal') //FIXME nonstard
                                });
    //node.appendChild(this.inputnode);
    this.inputnode.addEventListener("change", this.onChange.bind(this));

    return this.inputnode;
/*
    this.inputnode.set('required',data.required ? '' : null);
    this.inputnode.set('readonly',data.enabled ? null : '');*/
  }

  onDomInsertion()
  {
    replaceRangeComponent(this.node, { resizelistener : true });
  }

  onChange()
  {
    if(this.isEventUnmasked("change"))
      this.queueEvent(this.owner.screenname + "." + this.name, "change", true);
  }


/****************************************************************************************************************************
 * Property getters & setters
 */

  setValue(newvalue)
  {
    newvalue = [newvalue].flatten();
    this.inputnode.set({ "value"  : (newvalue.length ? newvalue[0] : "") //html5 supports only single value
                       , "data-values" : (newvalue.length ? newvalue.join(',') : '')
                       });
  }

  getSubmitValue()
  {
    return this.getValue();
  }

  getValue()
  {
    return this.inputnode.value;
  }

/****************************************************************************************************************************
 * Component management
 */

/****************************************************************************************************************************
* Dimensions
*/

  calculateDimWidth()
  {
    this.width.min = 150;
    this.width.calc = 250;

    //if(this.inputnode.getAttribute('orient') != 'vertical')
    //  this.inputnode.retrieve('wh-ui-replacedby').fireEvent('wh-resized');
  }

  calculateDimHeight()
  {
    this.height.min = $todd.settings.grid_vsize * 2;
//    if(this.inputnode.getAttribute('orient') == 'vertical')
  //    this.inputnode.retrieve('wh-ui-replacedby').fireEvent('wh-resized');
  }

  relayout()
  {
    this.inputnode.setStyles({ width: this.width.set, height: this.height.set });
    this.inputnode.retrieve('wh-ui-replacedby').fireEvent('wh-resized');
    this.inputnode.fireEvent("wh-refresh");
  }
}
