import * as dompack from 'dompack';
import * as movable from 'dompack/browserfix/movable';
var Toolbar = require('../toolbar/toolbars');
var getTid = require("@mod-tollium/js/gettid").getTid;
require("./imageeditor.lang.json");
var toddImages = require("@mod-tollium/js/icons");
import Keyboard from 'dompack/extra/keyboard';

var moo = require('@mod-system/js/frameworks/mootools/core');
var Element = moo.Element;
var Class = moo.Class;
var Events = moo.Events;
var Options = moo.Options;

// Set to true to activate 'inline' mode, without the modal toolbar
var tool_inline = false;

var buttonicon;

var PhotoPoint = new Class(
{ Implements  : [Events,Options]
, surface: null
, refpoint: null // { x: 0, y: 0 }
, isactive: false
, activated: false

, options: {}

, initialize: function(toolbar, surface, options)
  {
    this.setOptions(options);
    this.surface = surface;

    this.refpointpanel = new Toolbar.Panel(
        { onClose: this.stop.bind(this)
        , onApply: this.apply.bind(this)
        , onCancel: this.cancel.bind(this)
        });
    this.refpointpanel._imgedittool = "refpoint";

    this.delbutton = new Toolbar.Button(this.refpointpanel,
          { label: getTid("tollium:components.imgedit.editor.delrefpoint")
          , icon: toddImages.createImage("tollium:actions/delete", 24, 24, "b")
          , onExecute: this.clearPoint.bind(this)
          });
    this.refpointpanel.addButton(this.delbutton);

    this._setPoint = this.setPoint.bind(this);
    this.keyboard = new Keyboard(this.surface.container, { Delete: this.clearPoint.bind(this) });
    if (tool_inline)
    {
      this.surface.addEvent("reset", this.resetPoint.bind(this));
      this.surface.addEvent("showpreview", this.activate.bind(this, true));
      this.surface.addEvent("hidepreview", this.activate.bind(this, false));
    }
    else
      this.surface.addEvent("updatepreview", this.previewCanvasChanged.bind(this));
  }

, togglePointing: function(button)
  {
    if (!this.isactive)
    {
      this.start();
      toddImages.updateImage(buttonicon, "tollium:actions/reference", 24, 24, "w");
    }
    else
    {
      this.stop();
      toddImages.updateImage(buttonicon, "tollium:actions/reference", 24, 24, "b");
    }
    button.setPressed(this.isactive);
  }

, start: function(toolbar)
  {
    if (!tool_inline)
      toolbar.activateModalPanel(this.refpointpanel);
    this.refpoint = this.surface.refpoint;
    this.isactive = true;

    this.updateRefs();

    this.refpointer = new Element("div", { "class": "wh-refbox-pointer" });
    movable.enable(this.refpointer);
    this.refpointer.addEventListener("dompack:movestart", evt => this.moveStart(evt));
    this.refpointer.addEventListener("dompack:move", evt => this.move(evt));
    this.refpointer.addEventListener("dompack:moveend", evt => this.moveEnd(evt));

    this.activate(true);
  }

, cancel: function()
  {
    // Reset surface refpoint when cancelling
    this.surface.refpoint = this.surface.orgrefpoint;
  }

, stop: function()
  {
    this.activate(false);
    this.isactive = false;

    this.refpointer.destroy();
    this.refpointer= null;
    this.fireEvent("stop");
  }

, apply: function(fireapply)
  {
    this.applyCanvas({ refpoint : this.refpoint });
    if (fireapply !== false)
      this.fireEvent("apply", { refpoint : this.refpoint });
  }

, applyCanvas: function(props)
  {
    this.refpoint = props.refpoint;
    this.surface.refpoint = this.refpoint;
    if (!tool_inline)
      this.surface.fireEvent("refresh");
    this.updatePoint();
  }

, activate: function(active)
  {
    if (!this.isactive)
      return;

    active = !!active;
    if (active != this.activated)
    {
      var canvas = this.surface.previewcanvas || this.surface.canvas;
      this.activated = active;
      if (active)
      {
        canvas.addEvent("click", this._setPoint);
        this.surface.container.classList.add("wh-refbox");
        this.updatePoint();
      }
      else
      {
        canvas.removeEvent("click", this._setPoint);
        this.surface.container.classList.remove("wh-refbox");
        this.updatePoint(true);
      }
    }
  }

, previewCanvasChanged: function(event)
  {
    if (this.activated && event.oldcanvas)
    {
      event.oldcanvas.removeEvent("click", this._setPoint);
      var canvas = this.surface.previewcanvas || this.surface.canvas;
      canvas.addEvent("click", this._setPoint);
    }
  }

, moveStart: function(event)
  {
    event.stopPropagation();
    this.updateRefs();
  }

, move: function(event)
  {
    event.stopPropagation();
    var x = Math.max(this.reference.relpos.x, Math.min(this.reference.imgsize.x + this.reference.relpos.x, Math.round(this.refpoint.x * this.reference.canvasscale) + event.detail.movedX + this.reference.relpos.x));
    var y = Math.max(this.reference.relpos.y, Math.min(this.reference.imgsize.y + this.reference.relpos.y, Math.round(this.refpoint.y * this.reference.canvasscale) + event.detail.movedY + this.reference.relpos.y));
    this.refpointer.setStyles({ left: x
                              , top:  y
                              });
  }

, moveEnd: function(event)
  {
    event.stopPropagation();
    this.refpoint = { x: (this.refpointer.getStyle("left").toInt() - this.reference.relpos.x) / this.reference.canvasscale
                    , y: (this.refpointer.getStyle("top").toInt() - this.reference.relpos.y) / this.reference.canvasscale
                    };
    this.apply(tool_inline);
  }

, updateRefs: function()
  {
    var canvas = this.surface.previewcanvas || this.surface.canvas;
    this.reference = { abspos: canvas.getPosition()
                     , relpos: canvas.getPosition(this.surface.container)
                     , imgsize: canvas.getSize()
                     };
    this.reference.canvasscale = this.reference.imgsize.x / this.surface.canvasdata.realsize.x;
  }

, updatePoint: function(hide)
  {
    if (!this.refpointer)
      return;
    this.updateRefs();
    if (!hide && this.refpoint)
    {
      this.refpointer.setStyles({ left: Math.round(this.refpoint.x * this.reference.canvasscale + this.reference.relpos.x)
                                , top:  Math.round(this.refpoint.y * this.reference.canvasscale + this.reference.relpos.y)
                                }).inject(this.surface.container);
    }
    else
    {
      this.refpointer.dispose();
    }
  }

, setPoint: function(event)
  {
    this.refpoint = { x: (event.client.x - this.reference.abspos.x) / this.reference.canvasscale
                    , y: (event.client.y - this.reference.abspos.y) / this.reference.canvasscale
                    };
    this.apply(tool_inline);
  }

, clearPoint: function()
  {
    if (!this.isactive)
      return;
    this.refpoint = null;
    this.apply(tool_inline);
  }

, resetPoint: function()
  {
    this.refpoint = this.surface.refpoint;
    if (this.isactive)
      this.updatePoint();
  }
});

function addRefPointButton(toolbar, surface, options)
{
  var pointer = new PhotoPoint(toolbar, surface, options);
  pointer.addEvent("apply", function(props)
  {
    surface.pushUndo({action: "refpoint", comp: pointer, props: props, meta: true}, tool_inline);
  });

  buttonicon = toddImages.createImage("tollium:actions/reference", 24, 24, "b");
  var button = new Toolbar.Button(toolbar,
      { label: getTid("tollium:components.imgedit.editor.refpoint")
      , icon: buttonicon
      });

  if (tool_inline)
    button.toElement().addEventListener("execute", pointer.togglePointing.bind(pointer, button));
  else
    button.toElement().addEventListener("execute", pointer.start.bind(pointer, toolbar));
  toolbar.addButton(button);

  return { button: button, comp: pointer };
}

exports.addRefPointButton = addRefPointButton;
